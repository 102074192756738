import React,{useState,useContext,useEffect} from 'react'
import styled from 'styled-components'
import { BASE_URI, DEALERID} from '../context/Constants';
import { Link } from "react-router-dom";
import EltLogo from '../assets/images/elt-logo.png'
import Mail from '../assets/images/mail.png'
import Phone from '../assets/images/phone.png'
import Location from '../assets/images/location.png'
import { DealerContext } from '../context/Dealer'
import Instagram from '../assets/images/Instagram.png'
import axios from 'axios';
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";

const Root = styled.div`
background: black;
display: flex;
flex-direction: column;
align-items: center;

`

const Section = styled.div`

margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 0px;
background: black;
width: 100%;
display: flex;
color: white;
justify-content: center;
&:nth-child(2) {
    background: #444444;
  }

`

const Legal = styled.div`
justify-content: space-between;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 17px 0px;
background: black;
width: 100%;
display: flex;
color: white;
@media (min-width: 0px) and (max-width: 425px) {
    flex-direction:column;
     }
     @media (min-width: 426px) and (max-width: 768px) {
        flex-direction:column;
         }

`


const InnerSection = styled.div`
max-width: 1390px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 0px;
width: 70%;
display: flex;
color: white;
justify-content: space-between;
    align-items: flex-start;
@media (min-width: 0px) and (max-width: 425px) {
    flex-direction:column;
     }
     @media (min-width: 426px) and (max-width: 768px) {
        flex-direction:column;
         }
`

const Logo = styled.img`
@media (min-width: 0px) and (max-width: 425px) {
    width: 100px;
    padding-left: 1rem;
    padding-right: 1rem;
     }
     @media (min-width: 426px) and (max-width: 768px) {
        width: 100px;
        padding-left: 1rem;
        padding-right: 1rem;
         }
`
const InnerSectionHolder = styled.div`
max-width: 1390px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 0px;
width: 100%;
display: flex;
color: white;
justify-content: space-between;

@media (min-width: 0px) and (max-width: 425px) {
    flex-direction:column;
     }
     @media (min-width: 426px) and (max-width: 768px) {
        flex-direction:column;
         }

`

const InnerSectionFlexHolder = styled.div`
max-width: 1390px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 0px;
width: 100%;
display: flex;
color: white;
flex-direction:column;

`
const InnerSectionFlexHolderShare = styled.div`
max-width: 1390px;
margin-left: 0.5rem;
margin-right: 0.5rem;
margin-top: 1rem;
padding: 15px 0px;
width: 100%;
display: flex;
color: white;
flex-direction:column;

`

const Heading = styled.h1`
font-weight: 700;
font-size: 1.5em;
margin-bottom: 1rem;
`
const HeadingSmall = styled.h1`
font-weight: 700;
font-size: 1.5em;
margin-bottom: 1rem;
font-size: 0.7em;
`

const Text = styled.p`
margin:0px;
margin-bottom: 1rem;
line-height: 1.5;
`

const LegalText = styled.p`
margin:0px;
color:#a3aaaf;
@media (min-width: 0px) and (max-width: 768px) {
    margin-bottom: 1rem;
     }
  

`
const LegalTextHolder = styled.div`
padding: 0px 20px;

`

const TextSmall = styled.a`
margin:0px;
margin-bottom: 1rem;
line-height: 1.5;
font-size: .9em;
color: white;
text-decoration:none;
&:hover {
    color: #ffc015;
  }
`
const InfoHolder = styled.div`
display: flex;
    align-items: center;
    color: white;
text-decoration:none;
`


const InfoHolderLink = styled(Link)`
display: flex;
    align-items: center;
    color: white;
text-decoration:none;
`

const InfoIconHolder = styled.span`
padding-left: 1rem;
padding-right: 1rem;

`
const InfoIcon = styled.img`
border-radius:2px;
width:25px
`

const IconHolder = styled.div`
display: flex;

`

const InfoHolderMail = styled.a`

display: flex;
    align-items: center;
    color: white;
text-decoration:none;
`


function Footer() {


    const [footerData, setFooterData] = useState(false);
    const {globalDealer} = useContext(DealerContext)
    useEffect(() => {


        axios({
            method: 'GET',
            url: `${BASE_URI}/dealerWebsites/${DEALERID}`
        }).then((response) => {
    
            setFooterData({ logo: response.data.image });
    
        });
       
    }, []);

    return (
        <Root>

            <Section>
                <InnerSectionHolder>
                    <InnerSection>
                    <InfoHolder><InfoIconHolder><InfoIcon src={Phone}/></InfoIconHolder>{globalDealer?.newCarsPhoneNumber}</InfoHolder>
                    <InfoHolderLink to="/contact"><InfoIconHolder><InfoIcon src={Location}/></InfoIconHolder>{globalDealer?.address?.streetAddress}</InfoHolderLink>
                    <InfoHolderMail href={"mailto:" + `${globalDealer?.email}`}><InfoIconHolder><InfoIcon src={Mail}/></InfoIconHolder>{globalDealer?.email}</InfoHolderMail>
                    </InnerSection>
                    <Logo src={footerData.logo}/>
                </InnerSectionHolder>
            </Section>
            
            <Section>
                <InnerSectionHolder>

                    <InnerSectionFlexHolderShare>
                    <HeadingSmall>SHARE THIS PAGE</HeadingSmall>
                  <IconHolder>
                    <TextSmall href="https://www.facebook.com/bausedtruckandtrailer"><FacebookIcon style={{marginRight:"10px",width:'35px',height:'35px'}}/></TextSmall>
                    <TextSmall  href="https://www.instagram.com/bausedtruckandtrailer"><img src={Instagram} style={{marginRight:"10px",width:'35px',height:'35px'}}/></TextSmall>
                    <TextSmall  href="https://api.whatsapp.com/message/XN7B32RAIUQCM1?autoload=1&app_absent=0"><WhatsappIcon style={{marginRight:"10px",width:'35px',height:'35px'}}/></TextSmall>
                      </IconHolder>
                    </InnerSectionFlexHolderShare>

                    <InnerSectionFlexHolder>
                    <Heading>LINKS</Heading>
                    <TextSmall to="/stock">PRE-OWNED TRUCKS</TextSmall>
                    <TextSmall  to="/trailers">PRE-OWNED TRAILERS</TextSmall>
                    <TextSmall  to="/apply-for-finance">APPLY FOR FINANCE</TextSmall>
                    </InnerSectionFlexHolder>

                    <InnerSectionFlexHolder>
                    <Heading>BA Used Truck and Trailer</Heading>
                    <Text>This is the official website of The BA Used Truck and Trailer. Explore our used vehicles, offers, globalDealer information.</Text>
                    <img src={EltLogo} width='146px'/>
                    </InnerSectionFlexHolder>
            
                </InnerSectionHolder>
            </Section>

            <Legal>
            <LegalTextHolder>
                    <LegalText></LegalText>
             </LegalTextHolder>
             <LegalTextHolder>
                    <LegalText>2022 Copyright © BA Used Truck and Trailer</LegalText>
             </LegalTextHolder>
         
              
            </Legal>
            
          
        </Root>
    )
}

export default Footer
