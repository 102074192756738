import React from 'react';
import styled from 'styled-components'
import SellTruckForm from '../../components/SellTruckForm';

const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 0rem;
background: #ffffff;
justify-content:center;
border-radius:5px;

`


function SellYouTruck() {
  return (
    <Root>
       
        <SellTruckForm/>
    </Root>
  )

}

export default SellYouTruck;
