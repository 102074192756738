import React,{useContext,useState,useEffect} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { VehicleContext } from '../../../context/UsedVehicles';
import ContactForm from '../../../components/ContactForm'
import { Link } from "react-router-dom";
import FinanceCalulator from '../../../components/FinanceCalulator';
import { DEALERID,BASE_URI } from '../../../context/Constants';

import CarouselDetailed from '../../../components/CarouselDetailed'
import axios  from 'axios'

const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 0px;
background: #ffffff;

border-radius:5px;

@media (min-width: 0px) and (max-width: 425px) {
  flex-direction: column;
}
@media (min-width: 426px) and (max-width: 768px) {
  flex-direction: column;
}

`
const TitleHolder = styled.div`
padding: 0px 15px;
display:flex;
border-radius:5px;
align-items: center;
    justify-content: space-between;
    @media (min-width: 0px) and (max-width: 425px) {
      flex-direction: column;
    }
    @media (min-width: 426px) and (max-width: 768px) {
      flex-direction: column;
    }
`
const Title = styled.h2`
font-size: 2.5em;
width:60%;
margin: 0px;
@media (min-width: 0px) and (max-width: 425px) {
  width: unset;
  font-size: 1.5em;
}
@media (min-width: 426px) and (max-width: 768px) {
  width: unset;

}
`
const Price = styled.h1`
color: #777777;
font-weight: 700;
font-size: 3em;
margin: 0px;
width: 30%;
@media (min-width: 0px) and (max-width: 425px) {
  width: 100%;
  font-size: 2.5em;
}
@media (min-width: 426px) and (max-width: 768px) {
  width: 100%;
  font-size: 2.5em;
}
`

const InnerHolder = styled.div`
display:flex;
@media (min-width: 0px) and (max-width: 425px) {
  flex-direction: column;
}
@media (min-width: 426px) and (max-width: 768px) {
  flex-direction: column;
}
`

const LeftInnerHolder = styled.div`
padding:0rem 0.5rem;
width: 65%;
line-height:22px;
@media (min-width: 0px) and (max-width: 425px) {
  width: unset;
  padding:0rem 0.5rem;
}
@media (min-width: 426px) and (max-width: 768px) {
  width: unset;
  padding:0rem 0.5rem;
}
`

const RightInnerHolder = styled.div`

width: 35%;
@media (min-width: 0px) and (max-width: 425px) {
  width: unset;
}
@media (min-width: 426px) and (max-width: 768px) {
  width: unset;
  
}
`
const FinanceCalulatorHolder = styled.div`

margin-top:20px;
`
const ExtraInfo = styled.div`
background-color: #777777;
padding:0.2rem 0.5rem;

color: white;
margin:0px 0px;


`


const LinkStyled = styled(Link)`
max-width: calc(100%/2);
    width: 100%;
    text-align:center;
    text-decoration:none;
    font-size: 1.5em;
    background-color: #ffc015;
    border-radius: 25px;
    padding: 7px 20px;
    color: #fff;
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
`

const Vat = styled.span`
font-size: .5em;
`

function UsedTruckDetailed(props) {

  const para = useParams()
  const {vehicles,updateVehicles} = useContext(VehicleContext)
  const [activeVehicle,setActiveVehicle] = useState([])
  const [activeVehicleGallery,setActiveVehicleGallery] = useState([])
  const [selectedImage, setSelectedImage] = useState();


  useEffect(() => {

      //get trailer from list using id

      axios({
        method: 'GET',
        url: `${BASE_URI}/stock/${para.modelId || para.id}`,
    }).then((response) => {
      setActiveVehicle(response.data)
      setActiveVehicleGallery(response.data?.mainUrls)
      setSelectedImage(response.data?.mainUrls[0]);
    }).catch(error => {
      

    })
  }, [])


  return (
    <Root>
       <TitleHolder>
       <Title>{activeVehicle?.year} {activeVehicle?.brand} {activeVehicle?.model} </Title> <Price>R {activeVehicle?.price} <Vat >Incl Vat</Vat></Price>
       </TitleHolder>
       <hr/>
       <InnerHolder>
          <LeftInnerHolder>
       {console.log("activeVehicleGallery",activeVehicleGallery)}


          <img src={selectedImage} width="100%"/>
   
        {activeVehicleGallery.length > 1 &&
                                            <div style={{ marginBottom: '1rem' }}>
                                                {

                                                    <CarouselDetailed naturalSlideWidth={100}
                                                        naturalSlideHeight={60}
                                                        totalSlides={activeVehicleGallery.length}
                                                        visibleSlides={4}
                                                        isPlaying={true}
                                                        infinite
                                                        setSelectedImage={setSelectedImage}
                                                        slides={activeVehicleGallery}
                                                        onClick={(slide) => {
                                                     
                                                           if(slide.target.currentSrc){
                                                            setSelectedImage(slide.target.currentSrc);
                                                           }
                                                        }}
                                                    />
                                                }
                                            </div>
                                        }
          <p>{activeVehicle?.features} </p>
          {/* <p>PRICE EX VAT</p> */}
          <p>CONTACT US TO BOOK A VIEWING</p>
    
          </LeftInnerHolder>
          <RightInnerHolder>
          <ContactForm heading="Please Call Me" activeVehicle={activeVehicle}/>

          <FinanceCalulatorHolder>
          <FinanceCalulator price={activeVehicle?.price}/>
          </FinanceCalulatorHolder>
          <LinkStyled to={"/apply-for-finance/" + activeVehicle?.stockId}>
              Apply for Finance
          </LinkStyled>

          </RightInnerHolder>
       </InnerHolder>
    </Root>
  )

}

export default UsedTruckDetailed;
