import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI, DEALERID, MOTORGROUPID, MASTER_DEALERID, MASTER_DEALER_NAME } from './Constants'

export const DealerContext = createContext();

export const DealerProvider = ({ children }) => {

    const getItem = (itm) => {
        let data = localStorage.getItem(itm);

        if (data && data != "undefined")
            return JSON.parse(data)
        else
            return {
                id: MASTER_DEALERID,
                name: null
            }
    }

    const defaultColors = {
        primaryColor: '#ffc015',
        secondaryColor: '#d6ebf9',
        accentColor: '#ffffff',

    }
    const setItem = (name, object) => {
        var stringObject = JSON.stringify(object);
        localStorage.setItem(name, stringObject);
    }

    const [globalDealer, setGlobalDealer] = useState(getItem('globalDealer'));
    const [websiteColors, setWebsiteColors] = useState(getItem('websiteColors'))
    const [dealerList, setDealerList] = useState()
 
    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const getWebsiteColors = async () => {
            try {
                const result = await axios({
                    method: 'GET',
                    url: `${BASE_URI}/DealerWebsites/Colors/${DEALERID}`,
                    cancelToken: source.token
                })

                if (result?.data) {
                    const colorsObject = result?.data
                    setWebsiteColors(colorsObject);
                    setItem('websiteColors', colorsObject)
                }

            } catch (error) {
            }
        }

 
        getWebsiteColors();
      
    }, [])

    useEffect(() => {
        axios.get(`${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`, {

        }).then((result) => {
            console.log(result)
            var filtered = result.data.filter(d => d.name == MASTER_DEALER_NAME)
            setDealerList(filtered)
           
        });
    }, [])

    useEffect(() => {
        if (!websiteColors || !websiteColors?.primaryColor || !websiteColors?.secondaryColor || !websiteColors?.accentColor) {
            setWebsiteColors({
                ...websiteColors,
                primaryColor: websiteColors?.primaryColor ? websiteColors?.primaryColor : defaultColors.primaryColor,
                secondaryColor: websiteColors?.secondaryColor ? websiteColors?.secondaryColor : defaultColors.secondaryColor,
                accentColor: websiteColors?.accentColor ? websiteColors?.accentColor : defaultColors.accentColor
            })
        }
    }, [websiteColors])

    async function lookupDealer(name) {

        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();

        await axios({
            method: 'GET',
            url: `${BASE_URI}/Dealers/${MOTORGROUPID}/${name}`,
            cancelToken: source.token
        }).then((response) => {
console.log("response",response)
            let globalDealer = {
                id: response.data.id,
                name: response.data.name,
                makes: response.data.makes,
                social:response.data.social,
                resolved: true
            };

            setGlobalDealer(globalDealer);

            localStorage.setItem('globalDealer', JSON.stringify(globalDealer));

        }).catch(error => {
            if (axios.isCancel(error))
                return

            throw Error(error);
        });
    }

    useEffect(() => {

        var dealerName = ""

        const parsedData = window.location.pathname.split("/");

        if (parsedData.length > 1 && parsedData[1] == "globalDealer") {
            dealerName = parsedData[2];
        }
        else {
            let globalDealer = {
                id: DEALERID,
                name: null,
                resolved: true
            };

            localStorage.setItem('globalDealer', JSON.stringify(globalDealer));

            setGlobalDealer(globalDealer);
        }

        if (dealerName && dealerName.length > 0 && globalDealer.name != dealerName) {
            lookupDealer(dealerName);
        } else {
            lookupDealer(MASTER_DEALER_NAME)
        }

    }, [])

    return (
        <DealerContext.Provider value={{ globalDealer, websiteColors,dealerList }}>
            {children}
        </DealerContext.Provider>
    )
};