import React, { useEffect, useState,useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from '@mui/material/Slider';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: 'black',
        padding: theme.spacing(1, 15, 1),
        display: 'flex',
        alignItems: 'center',
        position:'fixed',
        zIndex:'99',
        '@media (max-width: 426px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
    },
    holder:{
     width:"100%",
  
     background:'white',
     margin:'0 auto',

     '@media (max-width: 1024px) ': {
     
        width:"100%",
        overflow:'auto',
    }
    },
    answerHolder:{
      
        padding:"20px",
        background:'white',
        textAlign:'right',
        fontSize:'1.2rem',
        background:'#f9fafb'
       },
       header:{
        display: 'flex',
        padding:"10px 10px",
        justifyContent: 'space-between',
        background:'#d9d9d6',
        marginBottom:'15px',
        textTransform: 'initial!important'
       },
       input:{
        padding:"5px 10px",
     
        outline:'none',
        border:'none',
        borderBottom:'1px solid #ffc015',
        ':focus':{
            borderBottom:'1px solid blue',
        }

       },
       inputHalf:{
        padding:"5px 10px",
  
        outline:'none',
        border:'none',
      
        borderBottom:'1px solid #ffc015',
        ':focus':{
            borderBottom:'1px solid blue',
        }

       },
       
       close:{
        color:"red",
       },
       inputFlex:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '9px 11px',
        '@media (max-width: 426px) ': {
     
            width: 'unset',
        }
       },
       inputSliderFlex:{
        display: 'flex',
        flexDirection: 'column',

        padding: '9px 11px',
       },
       
       inputFlexRow:{
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 426px) ': {
     
            flexDirection: 'column',
        }
       },
       inner:{
       padding:"0px 20px"
       }
}));




export default function FinanceCalulator(props) {
 
 

    const classes = useStyles();
    const [periodList, setPeriodList] = useState([84,72,60,48,36,]);
    const [amount, setAmount] = useState("");
    const [period, setPeriod] = useState(periodList[1]);
    const [balloon, setBalloon] = useState("");
    const [balloonPercent, setBalloonPercent] = useState("");
    const [depositAmount, setDepositAmount] = useState("");
    const [depositPercent, setDepositPercent] = useState("");
    const [interestRatePercent, setInterestRatePercent] = useState(7);
    const [totalAmount, setTotalAmount] = useState();


    const values =
    {
        amount: `${props?.price? props?.price :amount}`,
        period : `${period}`,
        balloon: `${balloon}`,
        balloonPercent: `${balloonPercent}`,
        depositAmount: `${depositAmount}`,
        depositPercent: `${depositPercent}`,
        interestRatePercent: `${interestRatePercent}`,
    };
   
    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setPeriod(e.target.value)
        console.log(period)
        setFieldValue(fieldName, e.target.value)
    }

    const handleAmountChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setAmount(e.target.value)
        console.log( "Amount",e.target.value)
        setFieldValue(fieldName, e.target.value)
    }

    const handleInterestChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setInterestRatePercent(e.target.value)
        console.log( "interestRatePercent",e.target.value)
        setFieldValue(fieldName, e.target.value)
    }
   

    const handleBalloonChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setBalloon(e.target.value)
        console.log( "depositPercent",e.target.value / values.amount *100)
        setBalloonPercent(e.target.value / values.amount *100)
    }

 const handleDepositChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setDepositAmount(e.target.value)
        console.log( "depositPercent",e.target.value / values.amount *100)
        setDepositPercent(e.target.value / values.amount *100)
    }

    const handleBalloonPercent = (e, value, setFieldValue, fieldName, fieldId) => {
        setBalloonPercent(e.target.value)
        setFieldValue(fieldName, e.target.value)

        setBalloon(  values.amount*e.target.value/100 )
        setFieldValue("balloon", values.amount* e.target.value/100  )
    }
    const handleDepositPercent = (e, value, setFieldValue, fieldName, fieldId) => {
        setDepositPercent(e.target.value)
        setFieldValue(fieldName, e.target.value)

        setDepositAmount(  values.amount*e.target.value/100 )
        setFieldValue("depositAmount", values.amount* e.target.value/100  )
    }

 

   
useEffect(() => {
       let sum = values.amount
        var interestPM = interestRatePercent / 100 / 12;
        let months = values.period 
        let Total =""
    if(values.amount !== "" && balloon < 0){
    
         Total = (sum - depositAmount) * interestPM * (Math.pow(1 + interestPM, (months))) /(Math.pow(1 + interestPM, (months))-1) ;
        let installmentPM = Math.round(Total)
        setTotalAmount(installmentPM.toFixed(0))

    }else{
     
         Total = (sum - depositAmount - balloon) * interestPM * (Math.pow(1 + interestPM, (months))) /(Math.pow(1 + interestPM, (months))-1) ;
        let installmentPM = Math.round(Total)
        setTotalAmount(installmentPM.toFixed(0))
    }
    
  
  
    

}, [values])
    
    return (
        <div>
              <Formik
                    isInitialValid={false}
                    initialValues={values}
                   enableReinitialize={true}
                >
                {(props) => {  
                    let {
                    values: {
                        amount,
                        period,
                        balloon,
                        balloonPercent,
                        depositAmount,
                        depositPercent,
                        interestRatePercent,
                        
                    },
                    touched,
                    errors,
                    isValid,
                    submitForm,
                    handleBlur,
                    setFieldTouched,
                    setFieldValue,
                   
                } = props;
                return (

            <Form className={classes.holder}>
               
                <div className={classes.header}>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500" >
                finance calculator                
                </Typography>
          
                </div>
                <div className={classes.inner}>
                <Grid item  xs={12} md={12}
                    lg={12} className={classes.inputFlex}>
                        Amount
                    <input
                        id="amount"
                        name="amount"
                        type="text"
                        label="Amount"
                        fullWidth
                        className={classes.input}
                        value={amount}
                        variant="outlined"
                        helperText={touched.amount ? errors.amount : ""}
                        error={touched.amount && Boolean(errors.amount)}
                        onChange={(e, child) => handleAmountChange(e, child, setFieldValue, 'amount', 'amount')}
                    />
                </Grid>

                <Grid item  xs={12} className={classes.inputFlex}>
                    Period
                    <select
                     variant="outlined"
                     fullWidth
                     id="period"
                     className={classes.input}
                     select
                     label="Period"
                     value={period}
                     helperText={errors.period && touched.period ? errors.period : ''}
                     error={errors.period && touched.period}
                     onBlur={handleBlur("dealerId")}
                     onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'period', 'period')}
                 >
                     {periodList.map((period) => (
                         <option key={period.id} id={period} value={period}>
                             {period} Months
                         </option>
                     ))}
                 </select>
                </Grid>

                <div className={classes.inputFlexRow}>
                <div className={classes.inputFlex}>
                        Balloon (Rands)
                    <input
                        id="balloon"
                        name="balloon"
                        type="text"
                        label="Balloon (Rands)"
                        fullWidth
                        className={classes.inputHalf}
                        value={balloon}
                        variant="outlined"
                        helperText={touched.balloon ? errors.balloon : ""}
                        error={touched.balloon && Boolean(errors.balloon)}
                        onChange={(e, child) => handleBalloonChange(e, child, setFieldValue, 'balloon', 'balloon')}
                    />
                       </div>
                
                <div className={classes.inputFlex}>
                        Balloon %
                    <input
                        id=" balloonPercent"
                        name="balloonPercent"
                        type="text"
                        label="Balloon %"
                        fullWidth
                        className={classes.inputHalf}
                        value={balloonPercent}
                        variant="outlined"
                        helperText={touched.balloonPercent ? errors.balloonPercent : ""}
                        error={touched.balloonPercent && Boolean(errors.balloonPercent)}
                        onChange={(e, child) => handleBalloonPercent(e, child, setFieldValue, 'balloonPercent', 'balloonPercent')}
                      
                    />
             
                </div>
                </div>


                <div className={classes.inputFlexRow}>
                <div className={classes.inputFlex}>
                        Deposit Amount
                    <input
                        id=" depositAmount"
                        name="depositAmount"
                        type="text"
                        label="Deposit Amount"
                        fullWidth
                        className={classes.input}
                        value={depositAmount}
                        variant="outlined"
                        helperText={touched.depositAmount ? errors.depositAmount : ""}
                        error={touched.depositAmount && Boolean(errors.depositAmount)}
                        onChange={(e, child) => handleDepositChange(e, child, setFieldValue, 'depositAmount', 'depositAmount')}
                    />
                         </div>
                         <div className={classes.inputFlex}>
                    
                        Deposit %
                    <input
                        id=" depositPercent"
                        name="depositPercent"
                        type="text"
                        label="Deposit %"
                        fullWidth
                        className={classes.input}
                        value={depositPercent}
                        variant="outlined"
                        helperText={touched.depositPercent ? errors.depositPercent : ""}
                        error={touched.depositPercent && Boolean(errors.depositPercent)}
                        onChange={(e, child) => handleDepositPercent(e, child, setFieldValue, 'depositPercent', 'depositPercent')}
                    />
                     </div>
                     </div>

                     <div    className={classes.inputSliderFlex}>
                        Interest Rate  {interestRatePercent} %
                <Slider
                
                aria-label="Temperature"
                defaultValue={interestRatePercent }
                valueLabelDisplay="auto"
                step={0.5}
                marks
                min={7}
                max={14}
                onChange={(e, child) => handleInterestChange(e, child, setFieldValue, 'interestRatePercent', 'interestRatePercent')}
                />
                 
                 </div>
                 
                 </div>
                <div className={classes.answerHolder}>
                Monthly Installment: R{totalAmount}
                </div>
            
            </Form>
                );
            }}
            </Formik>
        </div>
    )
}