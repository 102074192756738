import React,{useEffect,useState} from 'react'
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { BASE_URI, DEALERID} from '../context/Constants';

import axios from 'axios';

const Desktop = styled.div`
align-items: center;
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 20px 0px;
background: #ffffff;
padding-left: 2rem;
padding-right: 2rem;
display:flex;
justify-content:center;
margin-bottom: 1rem;
flex-wrap: wrap;
border-radius:5px;
@media (min-width: 0px) and (max-width: 1024px) {
    display: none;
     }
`

const LinkStyled = styled(Link)`
color: #000000;
display: block;
display:flex;
padding: 1px 0px;
text-decoration: none;
text-transform: uppercase;
margin: 15px;
&:hover {
    color: #ffc015;
  }
`

const Mobile = styled.div`

display: block;
@media (min-width: 1025px)  {
    display: none;
     }
`
const Burger = styled.div`

display: block;

`

const Pattie = styled.div`
width:30px;
height:5px;
margin:5px;
background:black;
display: block;
`
const Dropdown = styled.div`

padding:10px 0px;
line-height:30px;
background:white;
display: block;
`

const MobileHolder = styled.div`
display: flex;
margin:20px 10px;
justify-content: space-between;
align-items: center;
`

const MobileLogo = styled(Link)`
display: flex;
`
const DesktopLogo = styled(Link)`
margin:20px 30px;
`
function Navbar() {
    const [navBarData, setNavBarData] = useState(false);
    const [menu, setMenu] = useState(false);

    useEffect(() => {


        axios({
            method: 'GET',
            url: `${BASE_URI}/dealerWebsites/${DEALERID}`
        }).then((response) => {
    
            setNavBarData({ title: response.data.title, subTitle: response.data.subTitle, logo: response.data.image });
    
            document.title = `${response.data.title}`
        });
       
    }, []);

    return (
   <>
             <Mobile>
            

           <MobileHolder>
            <MobileLogo to="/"><img src={navBarData.logo} width="120px" /></MobileLogo>

            <Burger onClick={()=> setMenu(menu? false:!menu )}>
            <Pattie/>
            <Pattie/>
            <Pattie/>
            </Burger>
           </MobileHolder>

            {menu && 
            <Dropdown>
            <LinkStyled to="/stock" onClick={()=> setMenu(false)}>Used Trucks</LinkStyled>
            <LinkStyled to="/trailers" onClick={()=> setMenu(false)}>Used Trailers</LinkStyled>
            <LinkStyled to="/apply-for-finance" onClick={()=> setMenu(false)}>Apply For Finance</LinkStyled>
            <LinkStyled to="/sell-your-vehicle" onClick={()=> setMenu(false)}>Sell Your Trucks</LinkStyled>
            <LinkStyled to="/history"  onClick={()=> setMenu(false)}>Our History</LinkStyled>
            <LinkStyled to="/contact" onClick={()=> setMenu(false)}>Contact</LinkStyled>
            </Dropdown>
            }
            
            </Mobile>

            <Desktop>
            <LinkStyled to="/stock">Used Trucks</LinkStyled>
            <LinkStyled to="/trailers">Used Trailers</LinkStyled>
            <LinkStyled to="/apply-for-finance">Apply For Finance</LinkStyled>

            <DesktopLogo to="/"><img src={navBarData.logo} width="120px" /></DesktopLogo>
            
            <LinkStyled to="/sell-your-vehicle">Sell Your Trucks</LinkStyled>
            <LinkStyled to="/history">Our History</LinkStyled>
            <LinkStyled to="/contact">Contact</LinkStyled>
            </Desktop>
            </>
    )
}

export default Navbar
