import React,{useState,useEffect,useContext} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PQForm from '../../components/PQForm';
import { VehicleContext } from '../../context/UsedVehicles';

const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 10px 0px;
background: #ffffff;
border-radius:5px;
`

const HeadingHolder = styled.h2`
    background-color: #ddd;
    margin-bottom: 0rem;
    margin-top:0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    text-align:center;
    `

    const InnerHolder = styled.div`
display:flex;
@media (min-width: 0px) and (max-width: 768px) {
  flex-direction:column;
   }
    `

    const RightInnerHolder = styled.div`
    width: 70%;
    border: 1px solid #c0c0c0;
    margin-top:15px;
    margin-left:10px;
    @media (min-width: 0px) and (max-width: 768px) {
      width: unset;
      margin-right:10px;
       }
    `
    
    const LeftInnerHolder = styled.div`
    width: 30%;
    line-height: 1.5;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @media (min-width: 0px) and (max-width: 768px) {
      width: unset;
       }
    `
    const LeftInnerHolderHeading = styled.p`
    font-size: 2em;
    
    `   
    const LeftInnerHolderHeadingMail = styled.p`
 
    font-weight: 700;
    `

    const Legal = styled.p`
    font-size: 0.7em;

    `

export default function ApplyFinancePage() {
  const params = useParams()
  const {vehicles,updateVehicles} = useContext(VehicleContext)

  const [activeVehicle,setActiveVehicle] = useState([])

  const getVehicle = ()=>{

    let filter = vehicles?.filter((v)=> v?.stockId == parseInt(params?.modelId))

    setActiveVehicle(filter)


  }
  
  useEffect(() => {
    console.log("params",params?.modelId)
    getVehicle()
  }, [vehicles]);


  return (
      <Root>
        {params?.modelId !== undefined && 
        <HeadingHolder>
        Application for finance on used {activeVehicle[0]?.brand} {activeVehicle[0]?.model} 
        </HeadingHolder>
        }

        {params?.modelId == undefined && 
        <HeadingHolder>
        Application for finance through BA Used Truck and Trailer
        </HeadingHolder>
        }
      <InnerHolder>
      <LeftInnerHolder>
     <LeftInnerHolderHeading> Need help?</LeftInnerHolderHeading>

   <p>
   We are here to provide you with assistance, you can call us on :
+27 82 050 6696
during office hours.
   </p>

   <LeftInnerHolderHeadingMail> Mail us for assistance</LeftInnerHolderHeadingMail>

<Legal>
The National Credit Act was signed into law by the South African President on 15 March 2005, and governs the assessment, application and maintenance of credit granted by a credit provider to a consumer within the Republic of South Africa.
</Legal>
        </LeftInnerHolder>
        <RightInnerHolder>
          <PQForm vehicle={activeVehicle}/>
        </RightInnerHolder>
      </InnerHolder>
      </Root>
  )
}
