export const BASE_URI = process.env.REACT_APP_CONEXA_API_URL;
//export const BASE_URI = "http://localhost:56746/api"
export const SELF_URI = "http://localhost:3000/renault"
export const WEPAY4CARS_URI = "https://c2-wepay4cars-marketdealer.azurewebsites.net/api";
export const PAGE_SIZE = 1000
export const HAS_MULTIPLE_DEALERSHIPS = false
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;

export const DEALERID = 251
export const MASTER_DEALERID = 251
export const MASTER_CONFIGURATOR_DEALERID = 251
export const MASTER_DEALER_NAME = "BA-Used-Truck-and-Trailer"
export const MOTORGROUPID = 47
export const SECONDARYCOLOR = "white"
export const BASECOLOR = "white"


export const VIRTUAL_URI_RANGE = `https://configurator.renaultmotors.co.za/${DEALERID}/range/:leadId`
export const VIRTUAL_URI_DERIVATIVE = `https://configurator.renaultmotors.co.za/${DEALERID}/:modelId/builder/:leadId`
export const VIRTUAL_URI_APPLICATION = `https://configurator.renaultmotors.co.za/${DEALERID}/1/vehicle/finance/0/:leadId`



