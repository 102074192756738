import React,{ useState, useContext, useEffect} from 'react'
import styled from 'styled-components';
import trailer1 from "../../assets/images/Huntrex-Superlink-Trailer.jpg"
import trailer2 from "../../assets/images/UBT-Tri-axle-trailer.jpg"
import trailer3 from "../../assets/images/Tautliner-Afrit.jpg"
import TrailerCard from '../../components/TrailerCard';
import VehicleCard from '../../components/VehicleCard';
import { VehicleContext } from '../../context/UsedVehicles';

const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 20px 0px;
background: #ffffff;
display:flex;
flex-wrap:wrap;
border-radius:5px;
justify-content:center;
align-content: end;
@media (min-width: 0px) and (max-width: 425px) {
  flex-direction: column;
  width:unset;
}
@media (min-width: 426px) and (max-width: 768px) {
  flex-direction: column;
  width:unset;
}
`

const Card = styled.div`
max-width: calc(100%/3.5);
width: 100%;
margin: 7px;
@media (min-width: 0px) and (max-width: 537px) {
  max-width: calc(100%/1.05);
}
@media (min-width: 538px) and (max-width: 768px) {
  max-width: calc(100%/1.05);
  width: 100%;
}
`

// const vehicles = [{id: 0, image: trailer1, brand: "Huntrex Superlink flat deck", year: "2007", model: "Huntrex Superlink flat deck", price: "195,000.00"},
// {stockId: 1, image: trailer3, brand: "Tautliner Afrit", year: "2011", model: "Tautliner Afrit", price: "295,000.00"},
// {stockId: 2, image: trailer2, brand: "UBT Tri axle flat deck", year: "2011", model: "UBT Tri axle flat deck"}]

function UsedTrailers() {

  const { vehicles, updateVehicles} = useContext(VehicleContext)

  useEffect(() => {
    updateVehicles(); //create one for trailers / filter out trailers
  }, [])

    return (
        <Root>
          {vehicles.filter((v) => v.mmCode?.length < 6).map((trailer) => (
            <Card>
              <TrailerCard key={trailer.stockId} id={trailer.stockId} image={trailer.image} make={trailer.brand} model={trailer.model} price={trailer?.price} year={trailer.year} />  
            </Card>
            // <VehicleCard type="Trailer"  make={trailer.title} model={trailer.subtitle} year={trailer.year} price={trailer?.price} image={trailer.image} stockId={trailer.id} vehicle={trailer}/>
            
          ))}
            
        </Root>
    )
}

export default UsedTrailers
