import React from 'react'
import styled from 'styled-components'
import { Link, useHistory } from "react-router-dom";
import TrailerCard from '../components/TrailerCard'
import PriceFormatter from './PriceFormatter';

const Root = styled.div`
width: calc(100%/1.05);

border: 1px solid #c0c0c0;
margin-left: 0.5rem;
border-radius:5px;
margin-right: 0.5rem;
margin-bottom: 1rem;

text-align:center;
color: #777777;
display:flex;
align-items:center;
flex-direction:column;
@media (min-width: 0px) and (max-width: 537px) {
    max-width: calc(100%/1);
    width: unset;
  }

  @media (min-width: 538px) and (max-width: 768px) {
    max-width: calc(100%/1);
    width: unset;
  }

`


const RootHome = styled.div`
width: calc(100%/1);

border: 1px solid #c0c0c0;
margin-left: 0.5rem;
border-radius:5px;
margin-right: 0.5rem;
margin-bottom: 1rem;

text-align:center;
color: #777777;
display:flex;
align-items:center;
flex-direction:column;
@media (min-width: 0px) and (max-width: 537px) {
    max-width: calc(100%/1);
    width: 100%;
  }

  @media (min-width: 538px) and (max-width: 768px) {
    max-width: calc(100%/1);
    width: 100%;
    
  }

`
const Make = styled.span`
font-size: 1.5em;
line-height: normal;
margin:5px 0px;
display:inline-block;
`
const MileAge = styled.span`
font-size: .9em;
margin:5px 0px;
display:inline-block;
`
const PriceTruck = styled.span`
font-size: 1.5em;
line-height: normal;
margin:5px 0px;
display:inline-block;
font-weight: 700;
`
const ExtraInfo = styled.span`
background-color: #777777;
padding-left: 0.5rem;
padding-right: 0.5rem;
color: white;
margin:5px 0px;
display:inline-block;
`
const Info = styled.div`
padding-top: 0.5rem;
padding-bottom: 0.5rem;
color:black;
font-weight:bold;
text-decoration:none!important;
`


const Cta = styled.div`
margin-bottom: 1rem;
color: #ffffff;
background-color: #ffc015;
border-radius: 25px;
padding: 7px 20px;
width: 50%;
`

const ImageTruck = styled.img`
margin-bottom: 1rem;
`

const ImageTruckHome = styled.img`
margin-bottom: 1rem;
`

const ImageHolder = styled.div`

`

const CtaText = styled(Link)`
color: #ffffff;
background-color: #ffc015;
text-decoration: none;
font-weight: 700;

`

const Vat = styled.span`
font-size: .7em;
`

const InnerHolder = styled.div`
width: 100%;
min-height:300px;
`

function VehicleCard(props) {
  const history = useHistory()
    return (
      <>

           {props.type == "TruckHome" && 
            <RootHome onClick={()=>{history.push("/stock/" + props.stockId)}}>

            <div>
            <ImageHolder>
            <ImageTruckHome src={props.image} width="100%"/>
            </ImageHolder>
            <Make>{props.make}</Make><br/>
            <MileAge>{props.mileage}</MileAge><br/>
            <PriceTruck>R {PriceFormatter(props.price  / 1.15)} <Vat >Excl Vat</Vat></PriceTruck><br/>
          {!!props?.price && (<PriceTruck>R{PriceFormatter(props.price)} <Vat >Incl Vat</Vat></PriceTruck>)}
      
            </div>
            <Info>{props.model}</Info>
            <br/>
            <Cta>
            <CtaText to={"/stock/" + props.stockId}>Enquire</CtaText>
            </Cta>

            </RootHome>

            }
            
          {props.type == "Truck" && 
          <Root onClick={()=>{history.push("/stock/" + props.stockId)}}>

            <InnerHolder>
            <ImageTruck src={props.image} width="100%"/>
            <Make>{props.make}</Make><br/>
            <MileAge>{props.mileage}</MileAge><br/>
            <PriceTruck>R {PriceFormatter(props.price  / 1.15)} <Vat >Excl Vat</Vat></PriceTruck><br/>
          {!!props?.price && (<PriceTruck>R{PriceFormatter(props.price)} <Vat >Incl Vat</Vat></PriceTruck>)}
            </InnerHolder>
            <Info>{props.model}</Info>
            <br/>
            <Cta>
            <CtaText to={"/stock/" + props.stockId}>Enquire</CtaText>
            </Cta>

            </Root>

            }

            {props.type == "Trailer" && 
            <Root onClick={()=>{history.push("/trailer/" + props.stockId)}}>
            <TrailerCard to={"/trailer/" + props.stockId} image={props.image} title={props.title} year={props.year} subtitle={props.subtitle} price={props.price}/>
            </Root>
            }

    </>
    )
}

export default VehicleCard
