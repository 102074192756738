import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Switch, Route, Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Homepage from "./pages/HomePage/Homepage";
import SellYourTruck from "./pages/SellYourTruck/SellYouTruck";
import UsedTrucks from "./pages/UsedTruckPage/UsedTrucks";
import UsedTrucksDetailed from "./pages/UsedTruckPage/UsedTruckDetailedPage/UsedTruckDetailed";
import Navbar from "./components/Navbar";
import Covidbanner from "./components/Covidbanner";
import CookiePopUp from "./components/Cookie";
import OurHistory from "./pages/OurHistory/OurHistory";
import ContactUs from "./pages/ContactUs/ContactUs";
import ApplyFinancePage from "./pages/ApplyFinancePage/ApplyFinancePage";
import Footer from "./components/Footer";
import UsedTrailers from "./pages/UsedTrailer/UsedTrailers";
import TrailerDetail from "./pages/UsedTrailer/TrailerDetail";
import Privacy from "./pages/privacy/PrivacyPage";
import Terms from "./pages/Terms/TermsAndConditions";
import CookiePolicy from "./pages/cookies/CookiePolicy";
import Policies from "./pages/Policies/Policies";
import PopiRequest from "./pages/PopiRequest/PopiRequest";
import { VehicleProvider } from "./context/UsedVehicles";
import { DealerProvider } from "./context/Dealer";
import Carousel from "./components/Carousel";
import { DEALERID, BASE_URI } from "./context/Constants";
import axios from "axios";

import { useHistory } from "react-router-dom";
const Root = styled.div`
  width: 100%;
  max-width: 1404px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 0px;
`;

const SlideHodler = styled.div`
  margin-bottom: 1rem;
`;

function App() {
  const [slides, setSlides] = useState();
  const history = useHistory();
  useEffect(() => {
    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 1,
          pageSize: 100,
          status: "active",
          dealerId: 251,
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
        });

        var slides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            url: itm.url,
          };
        });

        setSlides(slides);
      } catch (error) {}
    };

    getSlides();
  }, []);

  const onSlideClick = (index) => {
    let redirectUrl = slides[index].content?.props?.url;
    if (redirectUrl) {
      history.push(redirectUrl.toLowerCase());
    }
  };


  return (
    <BrowserRouter>
      <DealerProvider>
        <CookiePopUp />
        <Covidbanner />
        <Root>
          <Navbar />
          {slides?.length > 0 && (
            <SlideHodler>
              <Carousel          
              naturalSlideWidth={100}
   
              totalSlides={slides.length}
              isPlaying={true}
              slideRedirect={true}
              infinite
              slides={slides}
              onSlideClick={(index) => onSlideClick(index)} />
            </SlideHodler>
          )}

          <VehicleProvider>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/stock" component={UsedTrucks} />
            <Route
              exact
              path="/stock/:modelId"
              component={UsedTrucksDetailed}
            />
            <Route exact path="/trailers" component={UsedTrailers} />
            <Route exact path="/trailer/:id" component={UsedTrucksDetailed} />
            <Route
              exact
              path="/apply-for-finance"
              component={ApplyFinancePage}
            />
            <Route
              exact
              path="/apply-for-finance/:modelId"
              component={ApplyFinancePage}
            />
            <Route exact path="/sell-your-vehicle" component={SellYourTruck} />
            <Route exact path="/history" component={OurHistory} />
            <Route exact path="/contact" component={ContactUs} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/cookies" component={CookiePolicy} />
            <Route exact path="/policies" component={Policies} />
            <Route exact path="/requesterdws" component={PopiRequest} />
          </VehicleProvider>
        </Root>
        <Footer />
      </DealerProvider>
    </BrowserRouter>
  );
}

export default App;
