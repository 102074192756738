import React from 'react'

function PriceFormatter(price) {


  const Format = (price)=>{
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ZAR',
      
      });
      return  formatter.format(price)
  }
  

  return (
    Format(price).replace("ZAR","").replaceAll(","," ").replaceAll("-"," ")
  )
}

export default PriceFormatter