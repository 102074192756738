import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 2rem;
background: #ffffff;
justify-content:center;
padding-bottom: 10rem;
border-radius:5px;
`

const Heading = styled.h1`
    font-size: 1.8em;
    color: #ffc015;
    line-height: 100%;
    margin: 30px auto;
`

const SubHeading = styled.div`
    margin: 15px 0;
    font-weight: bold;
`

const Link = styled.a`
  line-height: 2.5;
  cursor: pointer;
  color: #ffc015;
  text-decoration: none;
  &:hover {
      color: #000;
  }
`;

const Text = styled.p`
    margin-top: 0;
    margin-bottom: 1rem;
`
function Privacy() {
    return (
        <Root>
            <Heading>THE PROTECTION OF PERSONAL INFORMATION ACT CUSTOMER PRIVACY STATEMENT</Heading>

            <Text>This Notice explains how we obtain, use and disclose your personal information, in accordance with the requirements of the Protection of Personal Information Act (“POPIA”).</Text>
            <Text>At BA Used Truck and Trailer (and including this website, POPI Act-Compliance) we are committed to protecting your privacy and to ensure that your personal information is collected and used properly, lawfully and transparently.</Text>
            
            <SubHeading>The information we collect</SubHeading>
            
            <Text>We collect and process your personal information mainly to contact you for the purposes of understanding your requirements, and delivering services accordingly. For this purpose, we will collect contact details including your name and organisation.</Text>
            <Text>We collect information directly from you where you provide us with your personal details. Where possible, we will inform you what information you are required to provide to us and what information is optional.</Text>
            <Text>Website usage information may be collected using “cookies” which allows us to collect standard internet visitor usage information.</Text>
            
            <SubHeading>How we use your information</SubHeading>

            <Text>We will use your personal information only for the purposes for which it was collected and agreed with you. In addition, where necessary your information may be retained for legal or research purposes.</Text>
            <Text>For example:</Text>
            <ul>
                <li>To gather contact information;</li>
                <li>To confirm and verify your identity or to verify that you are an authorised user for security purposes;</li>
                <li>For the detection and prevention of fraud, crime, money laundering or other malpractice;</li>
                <li>To conduct market or customer satisfaction research or for statistical analysis;</li>
                <li>For audit and record keeping purposes;</li>
                <li>In connection with legal proceedings.</li>
            </ul>

            <SubHeading>Disclosure of information</SubHeading>

            <Text>We may disclose your personal information to our service providers who are involved in the delivery of products or services to you. We have agreements in place to ensure that they comply with the privacy requirements as required by the Protection of Personal Information Act.</Text>

            <Text>We may also disclose your information:</Text>

            <ul>
                <li>Where we have a duty or a right to disclose in terms of law or industry codes;</li>
                <li>Where we believe it is necessary to protect our rights.</li>
            </ul>

            <SubHeading>Information Security</SubHeading>

            <Text>We are legally obliged to provide adequate protection for the personal information we hold and to stop unauthorized access and use of personal information. We will, on an on-going basis, continue to review our security controls and related processes to ensure that your personal information remains secure.</Text>

            <Text>Our security policies and procedures cover:</Text>

            <ul>
                <li>Physical security;</li>
                <li>Computer and network security;</li>
                <li>Access to personal information;</li>
                <li>Secure communications;</li>
                <li>Security in contracting out activities or functions;</li>
                <li>Retention and disposal of information;</li>
                <li>Acceptable usage of personal information;</li>
                <li>Governance and regulatory issues;</li>
                <li>Monitoring access and usage of private information;</li>
                <li>Investigating and reacting to security incidents.</li>
            </ul>
            <Text>When we contract with third parties, we impose appropriate security, privacy and confidentiality obligations on them to ensure that personal information that we remain responsible for, is kept secure.</Text>

            <Text>We will ensure that anyone to whom we pass your personal information agrees to treat your information with the same level of protection as we are obliged to.</Text>

            <SubHeading>Your Rights: Access to information</SubHeading>

            <Text>You have the right to request a copy of the personal information we hold about you. To do this, simply contact us at the numbers/addresses as provided on our website and specify what information you require. We will need a copy of your ID document to confirm your identity before providing details of your personal information.</Text>

            <Text>Please note that any such access request may be subject to a payment of a legally allowable fee.</Text>

            <SubHeading>Correction of your information</SubHeading>

            <Text>You have the right to ask us to update, correct or delete your personal information. We will require a copy of your ID document to confirm your identity before making changes to personal information we may hold about you. We would appreciate it if you would keep your personal information accurate.</Text>

            <SubHeading>Definition of personal information</SubHeading>

            <Text>According to the Act‘‘personal information’’means information relating to an identifiable, living, natural person, and where it is applicable, an identifiable, existing juristic person. Further to the POPI Act, COR Concepts also includes the following items as personal information:</Text>

            <ul>
                <li>All addresses including residential, postal and email addresses;</li>
                <li>Change of name – for which we require copies of the marriage certificate or official change of name document issued by the state department.</li>
            </ul>

            <SubHeading>How to contact us</SubHeading>

            <Text>If you have any queries about this notice; you need further information about our privacy practices; wish to withdraw consent; exercise preferences or access or correct your personal information, please contact us at the numbers/addresses listed on our website.</Text>

            <SubHeading>Additional Links</SubHeading>

            <ul>
                <li><Link href="/requesterdws">Personal information request form</Link></li>
                <li><Link href="/policies">Policies</Link></li>
                <li><Link href="/cookies">Cookie Policy</Link></li>
                <li><Link href="/terms">Terms & Conditions</Link></li>
            </ul>
        </Root>
    )
}

export default Privacy
