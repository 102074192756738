import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

const Root = styled.div`
display:flex;
`


const Form = styled.form`
display:flex;
flex-direction: column;

background:#f8f8f8;
align-items:center;
`
const InnerForm = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;
width: 85%;
`

const InnerFormCheck = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;
width: 85%;
line-height:40px;
`

const Textarea = styled.textarea`
background:#f8f8f8;
outline:none;
border:none;
min-height:80px;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }
`

const Input = styled.input`
background:#f8f8f8!important;
outline:none;
border:none;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }

  
`
const Select = styled.select`
background:#f8f8f8!important;
outline:none;
border:none;

margin-top: 0.5rem;
margin-bottom: 0.5rem;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }
  @media (min-width: 0px) and (max-width: 425px) {
    width:100%;
  }
  @media (min-width: 426px) and (max-width: 768px) {
    width:100%;
  }
  
`

const HeadingHolder = styled.div`
background-color: #ffc015;
width: 100%;

`

const Heading = styled.h2`
color:white;
text-align:center;
margin: 10px 0px;
`

const Button = styled.button`
color: #ffffff;
background-color: #ffc015;
border-radius: 25px;
padding: 7px 20px;
width:max-content;
border:none;
margin-bottom: 0.5rem;
font-weight: 700;
text-transform: uppercase;
font-size:16px;
`

function PopiRequestForm(props) {
    const initialValue = {
        name:'',
        email:'',
        number:'',
        notes:'',
    }

    const [tandc,setTandC] = useState(false)
  
    return (
        <Root>
            
            <Formik
                initialValues={initialValue}
                onSubmit={(values,{setSubmitting , resetForm}) => {
                
                  if(tandc == true){
                    alert("Your request has been sent, we will be in touch")
                    setSubmitting(false);
                    resetForm()
                  
                  }
                  
                  }}
     >          
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
 
       }) => (
         
         <Form onSubmit={handleSubmit}>

               <InnerForm>
            
            <Select
       
              type="text"
              name="role"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              autocomplete={false}
              value={values.role}
              
            >
            <option>Select Role</option>
            <option>Customer</option>
            <option>Visitor</option>
            <option>Job Candidate</option>
            <option>Employee</option>
            <option>Vendor/Supplier</option>
            <option>Other</option>

            {errors.role && touched.role && errors.role}
            </Select>
 
      
            </InnerForm>

            <InnerForm>
            
            <Select
       
              type="text"
              name="type"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              autocomplete={false}
              value={values.type}
              
            >
            <option>Select Request Type</option>
            <option>Complaint</option>
            <option>Delete My Personal Information</option>
            <option>Provide All My Personal Information</option>
            <option>List All Type(s) of My Data Collected</option>
            <option>List All SOurces of My Data</option>
            <option>What is the Purpose of Collecting My Data?</option>
            <option>Give Us a Notice of Action</option>
            {errors.type && touched.type && errors.type}
            </Select>
 
      
            </InnerForm>

               <InnerForm>
              <label htmlFor="name">Name</label>
           <Input
             type="text"
             name="name"
             required
             onChange={handleChange}
             onBlur={handleBlur}
             autocomplete={false}
             value={values.name}
             label="Name"
           />
           {errors.name && touched.name && errors.name}
           </InnerForm>

           <InnerForm>
           <label htmlFor="email">Email</label>
           <Input
             type="email"
             name="email"
             required
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.email}
           />

           {errors.email && touched.email && errors.email}
           </InnerForm>

           <InnerForm>
           <label htmlFor="number">Phone number</label>
           <Input
             type="number"
             name="number"
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.number}
           />

           {errors.number && touched.number && errors.number}
           </InnerForm>

{props.notes?
           <InnerForm>
           <label htmlFor="number">Notes</label>
           <Textarea
             type="text"
             name="notes"
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.notes}
           />

           </InnerForm>: ""
 }
           <InnerFormCheck>
           <span>By submitting this form I agree to the <Link to="/terms">terms and conditions</Link> and <Link to="/privacy">privacy policies</Link> <input type="checkbox" required onChange={()=>{setTandC(tandc? false:true)}}/></span>
           </InnerFormCheck>

           <Button type="submit" disabled={isSubmitting}>
             Submit
           </Button>
           
         </Form>
       )}
     </Formik>
        </Root>
    )
}

export default PopiRequestForm
