import React, { createContext, useState, useEffect,useContext} from 'react';
import { DEALERID,BASE_URI } from './Constants';
import axios  from 'axios'

export const VehicleContext = createContext();



export const VehicleProvider = ({children}) => {


    const [vehicles, setVehicles] = useState([]);
 

    const getVehicles= () => {
  
        const params = {
            pageNumber: 1,
            pageSize: 10000,
            dealerId: DEALERID
        }
    
        axios({
            method: 'GET',
            url: `${BASE_URI}/stock`,
            params,
         
        }).then((response) => {
       
            let random = response.data?.list.sort(() => 0.5 - Math.random())
            setVehicles(random);

        }).catch(error => {
          
           console.log(error)
        })

    }
 

function updateVehicles() {
    console.log("updating")
    getVehicles()
}


 useEffect(() => {
    if(vehicles.length < 1){
        getVehicles()
    }

 }, [])
    
    return (
        <VehicleContext.Provider value={{vehicles,updateVehicles}}>
            {children}
        </VehicleContext.Provider>
    )
};