import React,{useState} from "react";

import "pure-react-carousel/dist/react-carousel.es.css";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    position: "relative",
    margin: "0.5rem",
  },
  carouselBack: {
    left: "10px",
    background: "#858585",
    borderRadius: "5px",
    height: "40px",
    width: "40px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%);",
    all: "unset",
    cursor: "pointer",
    "@media (max-width: 425px)": {
      top: "60%",
    },
  },
  carouselNext: {
    right: "10px",
    background: "#858585",
    borderRadius: "5px",
    height: "40px",
    width: "40px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%);",
    all: "unset",
    cursor: "pointer",

    "@media (max-width: 425px)": {
      top: "60%",
    },
  },
  carouselDots: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
    marginBottom: "15px",
    "& > button": {
      width: "15px",
      height: "15px",
      borderRadius: "50%",
      border: "none",
      padding: "0",
      cursor: "pointer",
      backgroundColor: "#EFDF00",
      marginRight: "10px",
    },
  },
  backgroundImage: {
    backgroundPosition: "center center",
    backgroundSize: "100% 100%!important",
    "@media (max-width: 624px)": {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top",
      height: "36%",
    },
  },

  slideMargin: {
    width: "calc(100% - 20px)",
    height: "calc(100%)",
    left: "10px",
    top: "10px",
  },
}));

const Carousel = ({ slides }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0)

  const handelBack = () =>{
    if(index > 0){
      setIndex(index - 1 )
    }
  }

  const handelNext = () =>{
    if(index < slides.length - 1){
      setIndex(index + 1 )
    }
  }
  return (
    <div className={classes.sliderContainer}>
   <div >
            <img src={slides[index].img} width="100%" />
          </div>
          {slides.length > 1 &&
     <>
      <div className={classes.carouselBack}  onClick={()=>{handelBack()}}>
        <ChevronLeftIcon style={{ fontSize: 40, color: "#EFDF00" }} />
      </div>
      <div className={classes.carouselNext} onClick={()=>{handelNext()}}>
        <ChevronRightIcon style={{ fontSize: 40, color: "#EFDF00" }} />
      </div>
      </>
      }
    </div>
  );
};

export default Carousel;
