import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik';
import { BASE_URI } from "../context/Constants"
import axios from "axios";
import { useSnackbar } from "notistack";
import SellYourTruckStep2 from './SellYourTruckStep2';

const Root = styled.div`
padding: 1rem;
border-radius:5px;
background:#f8f8f8;
margin: 15px;
`


const Formm = styled(Form)`
display:flex;
flex-direction: column;

background:#f8f8f8;
`
const FormInner = styled.div`
display:flex;
flex-direction: row;
width: 100%;
background:#f8f8f8;

align-items: flex-start;
@media (min-width: 0px) and (max-width: 425px) {
  flex-direction: column;
}
@media (min-width: 426px) and (max-width: 768px) {
  flex-direction: column;
}
`

const InnerForm = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;

`



const Textarea = styled.textarea`
background:#f8f8f8;
outline:none;
border:none;
min-height:80px;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }
`

const Input = styled.input`
background:#f8f8f8!important;
outline:none;
border:none;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }

  
`
const Select = styled.select`
background:#f8f8f8!important;
outline:none;
border:none;

margin-top: 0.5rem;
margin-bottom: 0.5rem;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }
  @media (min-width: 0px) and (max-width: 425px) {
    width:100%;
  }
  @media (min-width: 426px) and (max-width: 768px) {
    width:100%;
  }
  
`

const Button = styled.button`
color: black;
background-color: transparent;
border-radius: 25px;
padding:12px 20px;
width:max-content;
border:none;
cursor: pointer;
margin-top: 0.5rem;
margin-bottom: 0.5rem;
font-weight: 700;
text-transform: uppercase;
font-size:15px;
margin-left: -10px;
&:hover {
  color: #444;
  background-color: #ffc015;
}
`
const ButtonStep2 = styled.button`
color: white;
background-color: #ffc015;
border-radius: 25px;
padding: 7px 20px;
width:max-content;
border:none;
margin-top: 0.5rem;
margin-bottom: 0.5rem;
font-weight: 700;
text-transform: uppercase;
font-size:16px;
`

const CurentStepHolder = styled.div`
display: flex;

align-items: flex-start;
@media (min-width: 0px) and (max-width: 425px) {
  flex-direction: column;
}
@media (min-width: 426px) and (max-width: 768px) {
  flex-direction: column;
}
`
const Error = styled.span`
 color: red;
 font-size: 10px;
`

const Text = styled.p`
margin:0px;
margin-bottom: 1rem;
line-height: 1.5;
margin-left: 10px;
`
const Steps = styled.span`
font-weight: 700;
font-size: .9em;
margin-left: 10px;
`
const InputHolder = styled.div`
width:50%;
@media (min-width: 0px) and (max-width: 425px) {
  width:100%;
}
@media (min-width: 426px) and (max-width: 768px) {
  width:100%;
}
`
const Heading =styled.h1`
font-size: 2em;
color: black;
line-height: 100%;
margin: 30px auto;
text-align: center;
`

const ButtonHolder =styled.div`
display: flex;
    justify-content: space-between;
    font-weight: 700;
    align-items: center;
`
const InputRightHolder =styled.div`
display: flex;
    flex-direction:column;

    align-items: center;
    
    width: 100%;
    background: #e1e1e1;
    justify-content: center;

`
const InputHolderHeading =styled.h1`
margin: 5px;
font-weight: 700;
font-size: 1.5em;
`
const InputHolderRow =styled.div`

width: 100%;
display: flex;
&:nth-child(2n-2){
  background: #c1c1c1;
}
`
const InputHolderRowText =styled.p`
margin: 8px 15px;
width: 100%;

`
function SellTruckForm(props) {
  const [loader, setLoader] = useState(false);
  const [curentStep, setCurentStep] = useState(1);
  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState(null);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(null);
  const [variants, setVariants] = useState([]);
  const [variant, setVariant] = useState(null);
  const [years, setYears] = useState([]);
  const [Info, setInfo] = useState();
  const [year, setYear] = useState(null);
  const  enqueueSnackbar  = useSnackbar();
  
    const initialValue = {
        variant: '',
        variantName: '',
        fuel: '',
        range: '',
        rangeName: '',
        make: '',
        makeName: '',
        accident: '',
        gears: '',
        modelDetails: '',
        registrationyear: '',
        kilometers: '',
        registrationNumber: '',
        color: '',
    }

  useEffect(() => {
    const params = {
      motorgroupID: -1,
    };

    axios({
      method: "GET",
      url: `${BASE_URI}/Makes`,
      params,
    })
      .then((response) => {
        if (response.data?.list) {
          let makes = response.data?.list.map((make) => {
            return { id: make.id, name: make.name };
          });
          setMakes(makes);
        }
      })
      .catch((error) => {
        console.log(error)("Error occured getting makes", error);
      });
  }, []);

  useEffect(() => {
    if(!make?.id){
      return;
    }

    const params = {
      makeId: make?.id,
    };

    axios({
      method: "GET",
      url: `${BASE_URI}/Models`,
      params,
    })
      .then((response) => {
        if (response.data?.list) {
          let models = response.data?.list.map((model) => {
            return { id: model.id, name: model.model };
          });
          setModels(models);
        }
      })
      .catch((error) => {
        console.log(error)("Error occured getting models", error);
      });
  }, [make]);

  useEffect(() => {
    if(!make?.id || !model?.id) {
      return;
    }
    
    const params = {
      makeId: make?.id,
      modelId: model?.id,
    };

    axios({
      method: "GET",
      url: `${BASE_URI}/Variants`,
      params,
    })
      .then((response) => {
        if (response.data?.list) {
          let variants = response.data?.list.map((variant) => {
            return { id: variant.id, name: variant.variantName, startYear: variant.introYear, endYear: variant.discontinueYear };
          });
          setVariants(variants);
        }
      })
      .catch((error) => {
        console.log(error)("Error occured getting variants", error);
      });
  }, [make, model]);
  
  return (
      <>
        { curentStep ===1 &&<Heading>Your Vehicle Details</Heading>}
        { curentStep ===2 &&<Heading>Your Contact Details</Heading>}
        <Root style={{background:`${curentStep == 2?"transparent":''}`}}>    

        {curentStep == 2 && (<Button onClick={values => {values.registrationyear !== null && setCurentStep(curentStep-1)}}>
     {"Back <<"}
   </Button>)} <br />
          <Steps>Step {curentStep} of 2</Steps>
                { curentStep ===1 && <Text>You are just two steps away from getting a Free valuation for your vehicle.</Text>}
            <Formik
                initialValues={initialValue}
                validate={values =>{
                  const errors = {};
                    if (values.make == initialValue.make) {
                      errors.make = 'Required';
                    }
                    if (values.range == initialValue.range) {
                      errors.range = 'Required';
                    }
                    if (values.variant == initialValue.variant) {
                      errors.variant = 'Required';
                    }
                    if (values.gears == initialValue.gears) {
                      errors.gears = 'Required';
                    }
                    if (values.kilometers == initialValue.kilometers) {
                      errors.kilometers = 'Required';
                    }
                    if (values.registrationNumber == initialValue.registrationNumber) {
                      errors.registrationNumber = 'Required';
                    }
                    if (values.accident == initialValue.accident) {
                      errors.accident = 'Required';
                    }
                    if (values.color == initialValue.color) {
                      errors.color = 'Required';
                    }
                    if (values.fuel == initialValue.fuel) {
                      errors.fuel = 'Required';
                    }
                    if (values.registrationyear == initialValue.registrationyear) {
                      errors.registrationyear = 'Required';
                    }
                    return errors;
                }}                
                onSubmit={(values,{setSubmitting , resetForm}) => {
                  setTimeout(() => {
                    setInfo(values)
                      setCurentStep(curentStep+1)    
                  }, 0);
                  
                  }}
     >          
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         setFieldValue
 
       }) => (
         
         <Formm onSubmit={handleSubmit} style={{background:`${curentStep == 2?"transparent":''}`}}>
           <FormInner style={{background:`${curentStep == 2?"transparent":''}`}}>
{curentStep === 1 && 
<>
<InputHolder>
  
<InnerForm>
            
            <Select
       
              type="text"
              name="make"
              required
              onChange={(e, val) => {
                let v = +e.target.value;
                let currentMake = makes.filter(x => x.id == v)[0];
                setMake(currentMake);
                setFieldValue("make", v);
                setFieldValue("makeName", currentMake.name);
              }}
              onBlur={handleBlur}
              autocomplete={false}
              value={values.make}
              label="Vehicle's Brand"
              
            >
            <option>Vehicle's Brand</option>
            {makes.length > 0 && makes.map((make) =>(<option key={make.id} value={make.id}>{make.name}</option>))}
            </Select>
            {errors?.make && touched.make && <Error>{errors.make}</Error>}
      
            </InnerForm>
 
      
            <InnerForm>
             <Select
               type="text"
               name="range"
               required
               onChange={(e, val) => {
                let v = +e.target.value;
                let currentModel = models.filter(x => x.id == v)[0];
                setModel(currentModel);
                setFieldValue("range", v);
                setFieldValue("rangeName", currentModel.name);
              }}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.range}
               label="Vehicle's Range"
               
             >
             <option>Vehicle's Range</option>
             {models.length > 0 && models.map((model) =>(<option key={model.id} value={model.id}>{model.name}</option>))}
             </Select>
             {errors?.range && touched.range && <Error>{errors.range}</Error>}
             </InnerForm>
 
             <InnerForm>
             <Select
               type="text"
               name="fuel"
               required
               onChange={(e, val) => {
                let v = e.target.value;
                setFieldValue("fuel", v);
              }}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.fuel}
               label="Vehicle's Fuel"
               
             >
             <option>Vehicle's Fuel</option>
             <option value="p">Petrol</option>
             <option value="d">Diesel</option>
             
             </Select>
             {errors?.fuel && touched.fuel && <Error>{errors.fuel}</Error>}
             </InnerForm>
 
             
             <InnerForm>
             
             <Select
               type="text"
               name="gears"
               required
               onChange={(e, val) => {
                let v = e.target.value;
                setFieldValue("gears", v);
              }}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.gears}
               label="Vehicle's Gears"
               
             >
             <option>Vehicle's Gears</option>
             <option value="m">Manual</option>
             <option value="a">Automatic</option>
             
             </Select>
             {errors?.gears && touched.gears && <Error>{errors.gears}</Error>}
             </InnerForm>
 
             
             <InnerForm>
             
             <Select
               type="text"
               name="variant"
               required
               onChange={(e, val) => {
                let v = +e.target.value;
                let currentVariant = variants.filter(x => x.id == v)[0];
                setVariant(currentVariant);
                setFieldValue("variant", v);
                setFieldValue("variantName", currentVariant.name);
                let years = [];
                for(let i = +currentVariant.startYear; i <= +currentVariant.endYear; i++) {
                  years.push(i);
                }
                setYears(years);
              }}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.variant}
               label="Vehicle's Variant"
             >
             <option>Vehicle's Variant</option>
             {variants.length > 0 && variants.map((variant) =>(<option key={variant.id} value={variant.id}>{variant.name}</option>))}
             
             </Select>
             {errors?.variant && touched.range && <Error>{errors.variant}</Error>}
  
       
             </InnerForm>
             <InnerForm>
             Truck Details
             <Textarea
               type="text"
               name="modelDetails"
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.modelDetails}
               placeholder="If your truck is not listed above please supply details here..."
             />
             
             </InnerForm>
</InputHolder>
        
    
<InputHolder>
  
<InnerForm>
            
            <Select
              type="text"
              name="registrationyear"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              autocomplete={false}
              value={values.registrationyear}
              label="registration year"
              
            >
            <option>Vehicle's registration year?</option>
            {years.length > 0 && years.map((year) =>(<option key={year} value={year}>{year}</option>))}
            </Select>
            {errors?.registrationyear && touched.registrationyear && <Error>{errors.registrationyear}</Error>}
 
      
            </InnerForm>
 
      
            <InnerForm>
             
             <Select
               type="text"
               name="accident"
               required
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.accident}
               label="Accident"
               
             >
             <option>Has your vehicle ever been in significant accident?</option>
             <option value={true}>Yes</option>
             <option value={false}>No</option>
             
             </Select>
             {errors?.accident && touched.accident && <Error>{errors.accident}</Error>}
       
             </InnerForm>
 
             
             <InnerForm>
             <Input
               type="text"
               name="kilometers"
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               placeholder="Km's on the clock"
               value={values.kilometers}
               label="Km's on the clock"
             >
             
             </Input>
             {errors?.kilometers && touched.kilometers && <Error>{errors.kilometers}</Error>}
       
             </InnerForm>
 
             
             <InnerForm>
             
             <Input
               type="text"
               name="registrationNumber"
               placeholder="Registration Number?"
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.registrationNumber}
               label="Registration Number"
             >
             
             </Input>
             {errors?.registrationNumber && touched.registrationNumber && <Error>{errors.registrationNumber}</Error>}
             </InnerForm>
 
             
             <InnerForm>
             <Input
               type="text"
               name="color"
               placeholder="Exterior Colour?"
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.color}
               label="Exterior Colour"
               
             >             
             </Input>
             {errors?.color && touched.color && <Error>{errors.color}</Error>}
       
             </InnerForm>

             
</InputHolder>
</>
  
}



</FormInner>

{curentStep == 1 && <ButtonHolder>
     <Button type="submit">
     {"Next >>"}
   </Button>
   <>If none of these trucks apply to you – send and email to info@bausedtnt.com</>        </ButtonHolder>
   }
         </Formm>
         
       )}
     </Formik>

     {curentStep === 2 && 
<CurentStepHolder>
<InputHolder>
  <SellYourTruckStep2 vehicle={Info} />

</InputHolder>

<InputHolder>
<InputRightHolder>
  <InputHolderHeading>
  Your Vehicle
  </InputHolderHeading>

  <InputHolderRow>
  <InputHolderRowText>Make</InputHolderRowText>
  <InputHolderRowText>{Info?.makeName}</InputHolderRowText>
  </InputHolderRow>

  <InputHolderRow>
    <InputHolderRowText>Model</InputHolderRowText>
    <InputHolderRowText>{Info?.rangeName}</InputHolderRowText>
  </InputHolderRow>

  <InputHolderRow>
    <InputHolderRowText>Colour</InputHolderRowText>
    <InputHolderRowText>{Info?.color}</InputHolderRowText>
  </InputHolderRow>

  <InputHolderRow>
    <InputHolderRowText>Year</InputHolderRowText>
    <InputHolderRowText>{Info?.registrationyear}</InputHolderRowText>
  </InputHolderRow>

  <InputHolderRow>
    <InputHolderRowText>Gearbox</InputHolderRowText>
    <InputHolderRowText>{Info?.gears == "a" ? "Automatic" : Info?.gears == "m" ? "Manual": "" }</InputHolderRowText>
  </InputHolderRow>

  <InputHolderRow>
    <InputHolderRowText>Feul</InputHolderRowText>
    <InputHolderRowText>{Info?.fuel == "d" ? "Diesel" : Info?.fuel == "p" ? "Petrol" : ""}</InputHolderRowText>
  </InputHolderRow>

  <InputHolderRow>
    <InputHolderRowText>Mileage </InputHolderRowText>
    <InputHolderRowText>{Info?.kilometers} km</InputHolderRowText>
  </InputHolderRow>

  <InputHolderRow>
    <InputHolderRowText>Accident</InputHolderRowText>
    <InputHolderRowText>{Info?.accident == "true" ? "Yes" : Info?.accident == "false" ? "No" : ""}</InputHolderRowText>
  </InputHolderRow>

  <InputHolderRow>
    <InputHolderRowText>Registration Number</InputHolderRowText>
    <InputHolderRowText>{Info?.registrationNumber}</InputHolderRowText>
  </InputHolderRow>
</InputRightHolder>
</InputHolder>

</CurentStepHolder>
  
}

        </Root>
        </>
    )
}

export default SellTruckForm
