import React from 'react'
import styled from 'styled-components';
import PopiRequestForm from "./PopiRequestForm";

const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 10px;
padding-bottom: 0px;
background: #ffffff;
border-radius:5px;
`

const InnerHolder = styled.div`
max-width: 1404px;
display:flex;
padding: 15px 0px;
background: #ffffff;
@media (min-width: 0px) and (max-width: 425px) {
  flex-direction:column;
  }

`

const ContactDetailsHolder = styled.div`
max-width: 1404px;
background: #ffffff;
display:flex;

@media (min-width: 0px) and (max-width: 425px) {
    flex-direction:column;
    }
`

const ContactDetailsCard = styled.div`
width: calc(100%/3);
text-align:center;
:nth-child(2) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 425px) {
    width:unset;
    }
`


const ContactDetailsHeading = styled.div`
color: #000000;
background-color: #ffc015;
font-weight: 700;
padding:5px 0px;
`

const ContactDetailsText = styled.div`
padding: 10px 0px;
`

const InnerHolderMap = styled.div`
text-align:left;
width:80%;
margin: 0px 15px;
@media (min-width: 0px) and (max-width: 425px) {
    width:unset;
    }
`

const Heading = styled.h1`
  font-size: 1.8em;
  color: #ffc015;
  line-height: 100%;
  margin: 30px auto;
`;

const InnerContentHeading = styled.h1`
font-weight: 700;
font-size: 1.8em;
line-height: 100%;
margin: auto;
text-align:left;
`
const OrderedList = styled.ol`
  margin: 10px 0;
`

const ListItem = styled.li`
  line-height: 1.5;
`

function PopiRequest() {
    return (
        <Root>
            <Heading>Popi Request</Heading>
            <InnerHolder>
            <PopiRequestForm notes/>
            <InnerHolderMap>
                <InnerContentHeading>
                    Section 11 of PROTECTION OF PERSONAL INFORMATION ACT (POPIA)
                </InnerContentHeading>

                <h2>Consent, justification and objection</h2>
                
                <OrderedList>
                    <ListItem>Personal information may only be processed if—
                        <OrderedList type="a">
                            <ListItem>the data subject or a competent person where the data subject is a child consents to the processing;</ListItem>
                            <ListItem>processing is necessary to carry out actions for the conclusion or performance of a contract to which the data subject is party;</ListItem>
                            <ListItem>processing complies with an obligation imposed by law on the responsible party;</ListItem>
                            <ListItem>processing protects a legitimate interest of the data subject;</ListItem>
                            <ListItem>processing is necessary for the proper performance of a public law duty by a public body; or</ListItem>
                            <ListItem>processing is necessary for pursuing the legitimate interests of the responsible party or of a third party to whom the information is supplied.</ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem><br />
                        <OrderedList type="a">
                            <ListItem>The responsible party bears the burden of proof for the data subject’s or competent person’s consent as referred to in subsection (1)(a).</ListItem>
                            <ListItem>The data subject or competent person may withdraw his, her or its consent, as referred to in subsection (1)(a), at any time: Provided that the lawfulness of the processing of personal information before such withdrawal or the processing of personal information in terms of subsection (1)(b) to (f) will not be affected.</ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem>A data subject may object, at any time, to the processing of personal information—
                        <OrderedList type="a">
                            <ListItem>in terms of subsection (1)(d) to (f), in the prescribed manner, on reasonable grounds relating to his, her or its particular situation, unless legislation provides for such processing; or</ListItem>
                            <ListItem>for purposes of direct marketing other than direct marketing by means of unsolicited electronic communications as referred to in section 69.</ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem>If a data subject has objected to the processing of personal information in terms of subsection (3), the responsible party may no longer process the personal information.</ListItem>
                </OrderedList>
                


            </InnerHolderMap>
            </InnerHolder>
        </Root>
    )
}

export default PopiRequest
