import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { BASE_URI, DEALERID } from "../context/Constants"
import axios from "axios";
import { useSnackbar } from "notistack";

const Root = styled.div`
display:flex;
`


const Form = styled.form`
display:flex;
flex-direction: column;
width: 100%;
background:#f8f8f8;
align-items:center;
`
const InnerForm = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;
width: 85%;
`

const InnerFormCheck = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;
width: 85%;
line-height:40px;
`

const Textarea = styled.textarea`
background:#f8f8f8;
outline:none;
border:none;
min-height:80px;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }
`

const Input = styled.input`
background:#f8f8f8!important;
outline:none;
border:none;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }

  
`
const HeadingHolder = styled.div`
background-color: #ffc015;
width: 100%;

`

const Heading = styled.h2`
color:white;
text-align:center;
margin: 10px 0px;
`

const Button = styled.button`
color: #ffffff;
background-color: #ffc015;
border-radius: 25px;
padding: 7px 20px;
width:max-content;
border:none;
margin-bottom: 0.5rem;
font-weight: 700;
text-transform: uppercase;
font-size:16px;
`

function ContactForm({ activeVehicle, heading, notes }) {
  const [loader, setLoader] = useState(false)
  const enqueueSnackbar = useSnackbar();

  const initialValue = {
    name: '',
    lastName: "",
    email: '',
    number: '',
    notes: '',
  }

  const [tandc, setTandC] = useState(false)
  const [leadType, setleadType] = useState('')

  useEffect(() => {

    if (activeVehicle === undefined) {
      setleadType(3)
    } else {
      setleadType(2)
    }
  }, [])

  function createLead(values) {




    var sourceId = 1;

    if (document.referrer) {
      if (document.referrer.indexOf("facebook") > -1) {
        sourceId = 2;
      }
    }

    setLoader(true);

    let params = {}


    params.leadStatusId = 1
    params.dealerId = DEALERID
    params.name = values.name + ' ' + values.lastName
    params.firstName = values.name;
    params.lastName = values.lastName;
    params.phoneNumber = values.number
    params.leadSourceId = sourceId
    params.TypeCode = "NEW"
    params.leadTypeId = leadType

    if (activeVehicle && activeVehicle[0]?.stockId) {
      params.stockId = activeVehicle[0]?.stockId
      params.mmCode = activeVehicle[0]?.mmCode
    }
    params.notes = values.notes



    let m = new Date()
    m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
    params.dateUpdated = new Date().toISOString();

    params.emailAddress = values.email
    // setGlobalLead(params);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: 'POST',
      url: `${BASE_URI}/leads`,
      data: params,
      cancelToken: source.token
    }).then((response) => {
      values.leadId = response.data.id;
      setLoader(false);

    }).catch(error => {
      if (axios.isCancel(error)) return
      enqueueSnackbar("Unable to complete the request", { variant: 'error' });
      setLoader(false);

    })
  }


  return (
    <Root>

      <Formik
        initialValues={initialValue}
        onSubmit={(values, { setSubmitting, resetForm }) => {

          if (tandc == true) {

            createLead(values)
            setSubmitting(true);
            resetForm()

          }

        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (

          <Form onSubmit={handleSubmit}>
            <HeadingHolder><Heading>{heading}</Heading></HeadingHolder>

            <InnerForm>
              <label htmlFor="name">Name</label>
              <Input
                type="text"
                name="name"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                autocomplete={false}
                value={values.name}
                label="Name"
              />
              {errors.name && touched.name && errors.name}
            </InnerForm>

            <InnerForm>
              <label htmlFor="name">Last Name</label>
              <Input
                type="text"
                name="lastName"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                autocomplete={false}
                value={values.lastName}
                label="lastName"
              />
              {errors.lastName && touched.lastName && errors.lastName}
            </InnerForm>
            <InnerForm>
              <label htmlFor="email">Email</label>
              <Input
                type="email"
                name="email"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />

              {errors.email && touched.email && errors.email}
            </InnerForm>

            <InnerForm>
              <label htmlFor="number">Phone number</label>
              <Input
                  type="tel"
                name="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.number}
              />

              {errors.number && touched.number && errors.number}
            </InnerForm>

            {notes ?
              <InnerForm>
                <label htmlFor="number">Notes</label>
                <Textarea
                  type="text"
                  name="notes"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.notes}
                />

              </InnerForm> : ""
            }
            <InnerFormCheck>
              <span>By submitting this form I agree to the <Link to="/terms">terms and conditions</Link> and <Link to="/privacy">privacy policies</Link> <input type="checkbox" required onChange={() => { setTandC(tandc ? false : true) }} /></span>
            </InnerFormCheck>

            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>

          </Form>
        )}
      </Formik>
    </Root>
  )
}

export default ContactForm
