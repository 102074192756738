import React,{useState,useContext,useEffect} from 'react'
import styled from 'styled-components';
import VehicleCard from '../../components/VehicleCard';
import { UsedVehiclesFilter } from '@hyperboliq/leadcentre'
import { Link } from "react-router-dom";

import { VehicleContext } from '../../context/UsedVehicles';



const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 0px;
background: #ffffff;
display:flex;
border-radius:5px;
justify-content:center;
@media (min-width: 0px) and (max-width: 425px) {
  flex-direction: column;
}
@media (min-width: 426px) and (max-width: 768px) {
  flex-direction: column;
}

`
const VehicleCardHolder = styled.div`
max-width: 1404px;
padding: 15px 0px;
background: #ffffff;
display:flex;
justify-content:center;
flex-wrap:wrap;
width:80%;
  @media (min-width: 0px) and (max-width: 425px) {
    flex-wrap:wrap;
    width:unset;
  }
  @media (min-width: 426px) and (max-width: 768px) {
    flex-wrap:wrap;
    width:unset;
  }
`

const SelectMainHolder = styled.div`


justify-content: center;
width:20%;
line-height: 60px;
margin: 10px 0px;
margin-left:30px;

@media (min-width: 0px) and (max-width: 425px) {
  margin: 10px 20px;
  width:unset;
}
@media (min-width: 426px) and (max-width: 768px) {
  margin: 10px 0px;
  margin-left:30px;
  width:unset;
}
@media (min-width: 769px) and (max-width: 1440px) {
  margin: 10px 0px;
  margin-left:30px;

}
`

const LinkStyled = styled(Link)`
max-width: calc(100%/2.3);
    width: 100%;
    text-decoration:none;
    margin: 0 5px;
    @media (min-width: 0px) and (max-width: 425px) {
  
      max-width:unset;
    }
    @media (min-width: 426px) and (max-width: 768px) {
  
      max-width: calc(100%/2.3);
    }
`


function UsedTrucks() {
  const {vehicles,updateVehicles} = useContext(VehicleContext)
  const [pagedVehicles, setPagedVehicle] = useState([]);


  useEffect(() => {
    setPagedVehicle([vehicles.filter((v) => v.mmCode?.length >= 6)])
  
  }, [vehicles]);
 
  

    return (
        <Root>
           <SelectMainHolder>
           
        
           <UsedVehiclesFilter
                                pageSize={20}
                                multiSelect={true}
                                websiteColors={
                                  {primaryColor:'#ffc015 ',secondaryColor:'red',accentColor:'red'}
                                }
                                updateFilteredVehicles={setPagedVehicle}
                                vehicles={vehicles}
                            
                            />
        
            </SelectMainHolder>

            
            <VehicleCardHolder>
            {pagedVehicles[0]?.map((vehicle,index)=>{
                return(
                  <LinkStyled to={"/stock/" + vehicle.stockId}>
                    {console.log("vehicle",vehicle)}
                    <VehicleCard type="Truck"  make={vehicle.brand} model={vehicle.model} mileage={vehicle.mileage + "km" + " | " + vehicle.year} price={vehicle.price} image={vehicle.image} stockId={vehicle.stockId} vehicle={vehicle}/>
                  </LinkStyled>
                )
            })}
     
            </VehicleCardHolder>
        </Root>
    )
}

export default UsedTrucks
