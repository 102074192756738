import React from "react";
import styled from "styled-components";

const Root = styled.div`
  max-width: 1404px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 15px 2rem;
  background: #ffffff;
  justify-content: center;
  padding-bottom: 10rem;
  border-radius: 5px;
`;

const Heading = styled.h1`
  font-size: 1.8em;
  color: #ffc015;
  line-height: 100%;
  margin: 30px auto;
`;

const SubHeading = styled.div`
  margin: 15px 0;
  font-weight: bold;
`;

const ListItem = styled.li`
  line-height: 1.5;
`;

const Link = styled.a`
  line-height: 2.5;
  cursor: pointer;
  color: #ffc015;
  text-decoration: none;
  &:hover {
    color: #000;
  }
`;

const Text = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
`;
function Policies() {
  return (
    <Root>
      <Heading>POLICIES & DISCLAIMER</Heading>

      <Text>
        The disclaimer set out below applies to this website, all websites owned
        and operated by BA Used Truck and Trailer, unless such other website
        specifically provides otherwise, and to any electronic communication
        including any attachments thereto (collectively Electronic
        Communications) emanating from BA Used Truck and Trailer.
      </Text>
      <Text>
        For the purposes of this disclaimer, any reference to "BA Used Truck and
        Trailer" shall be interpreted to be a reference to BA Used Truck and
        Trailer, and includes all its subsidiaries, associated companies, and
        their internal divisions and employees.
      </Text>
      <Text>
        The disclaimer may change from time to time and without notice. The
        terms displayed at the time that you use the website will govern your
        use of the website. In accessing any Electronic Communications, whether
        it be by way of visiting this website or opening any electronic
        communication from BA Used Truck and Trailer, the person so doing
        accepts the following:
      </Text>
      <Text>
        Whilst BA Used Truck and Trailer has taken all reasonable steps to
        ensure that all information contained in Electronic Communications is
        current and accurate, it cannot guarantee the accuracy of the
        information nor that it is up to date. Use of Electronic Communications
        is entirely at the user’s own risk.
      </Text>

      <Text>
        Although BA Used Truck and Trailer has taken all reasonable steps to
        guard against viruses or destructive codes, it gives no warranty that
        Electronic Communications are free of viruses or any other data or code
        that has the ability to corrupt or affect your system.
      </Text>

      <Text>
        Electronic Communications may contain information that is confidential
        and subject to legal privilege. It is intended solely for the individual
        or entity to whom it is addressed and to others who have the authority
        to receive it. Unintended recipients of Electronic Communications may
        not under any circumstances disclose, copy, distribute or take action
        that relies on the content of the information contained therein.
      </Text>

      <Text>
        Where the contents of Electronic Communications expressly state the
        author thereof, any views expressed in the contents shall be construed
        to be, unless otherwise stated, the views of the author and not those of
        BA Used Truck and Trailer or any of its management or directors.
        Electronic Communications will only be legal, valid and enforceable on
        BA Used Truck and Trailer if an authorised BA Used Truck and Trailer
        employee or the head of the respective division has confirmed or
        consented thereto.
      </Text>

      <Text>
        Any information contained in Electronic Communications does not
        constitute advice as defined in the Financial Advisory and Intermediary
        Services Act, and the user thereof shall bear the responsibility to seek
        professional advice to ascertain whether any such information is
        suitable to his/her personal circumstances.
      </Text>

      <Text>
        BA Used Truck and Trailer may, in good faith, provided links that would
        appear useful to recipients of Electronic Communications, but does not
        make any representation regarding such links and does not endorse the
        products and/or services that may be offered thereon. Links are provided
        for convenience only, and may be discontinued at any time. BA Used Truck
        and Trailer accepts no responsibility for the content or use of
        information contained in the said links.
      </Text>

      <Text>
        Unless otherwise noted, Electronic Communications are subject to
        intellectual property rights that vest in BA Used Truck and Trailer. Use
        of the content in web pages, electronic or written publications or any
        other media and/or words, phrases, names, designs or logos that are BA
        Used Truck and Trailer intellectual property are expressly prohibited,
        without the express written permission of BA Used Truck and Trailer.
      </Text>

      <Text>
        BA Used Truck and Trailer disclaims all implied warranties, including
        but not limited to, warranties of compatibility, security and accuracy,
        and BA Used Truck and Trailer will not be liable for any special,
        indirect, incidental, consequential or punitive damages or any damages
        whatsoever, whether in an action of contract, statute, delict
        (including, without limitation, negligence) or otherwise that relate to
        the use of Electronic Communications. You agree to indemnify and hold
        harmless BA Used Truck and Trailer and its respective directors,
        officers, employees, agents, licensor’s, suppliers, and any third party
        information providers from and against all losses and expenses
        (including attorney-and own client costs), resulting from any failure to
        adhere to this disclaimer.
      </Text>

      <Text>
        All information contained in Electronic Communications pertaining to
        products and services on or accessed through the website are for your
        personal and non -commercial use only and their terms and conditions, is
        subject to change without notice.
      </Text>

      <Text>
        Electronic Communications are governed by all applicable South African
        legislation, including in particular (and without limiting the
        generality of the foregoing) sections 11 to 20 of the Electronic
        Communications and Transactions Act 25 of 2002. All legislation shall be
        deemed to include any amendments thereto or re-enactments thereof.
      </Text>

      <SubHeading>Policies</SubHeading>
      <ul>
        <ListItem>
          <Link href="/privacy">Privacy Policy</Link>
        </ListItem>
        <ListItem>
          <Link href="/cookies">Cookie Policy</Link>
        </ListItem>
        <ListItem>
          <Link href="/terms">Terms & Conditions</Link>
        </ListItem>
      </ul>

      <Text>
        If there are any questions regarding our Cookie Policy{" "}
        <Link href="/contact">contact us</Link>
      </Text>
    </Root>
  );
}

export default Policies;
