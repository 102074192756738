import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
import { BASE_URI, DEALERID } from "../context/Constants"
import axios from "axios";
import { useSnackbar } from "notistack";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormikRadioGroup from "./FormikRadioGroup";

const Root = styled.div`
display:flex;
`

const Form = styled.form`
display:flex;
flex-direction: column;
background:#f8f8f8;
align-items:center;
height: fit-content;
padding-bottom: 20px;
`

const InnerForm = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;
width: 85%;
`

const InnerFormCheck = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;
width: 85%;
line-height:20px;
`

const InnerFormRadio = styled.div`
margin-top: 15px;
display:flex;
flex-direction: column;
padding:10px 10px;
width: 85%;
& .MuiFormLabel-root, .MuiFormLabel-root.Mui-focused {
    color: #000;
}
`

const Input = styled.input`
background:#f8f8f8!important;
outline:none;
border:none;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }

  
`
const HeadingHolder = styled.div`
background-color: #ffc015;
width: 100%;

`

const Heading = styled.h2`
color:white;
text-align:center;
margin: 10px 0px;
`

const Button = styled.button`
color: #ffffff;
background-color: #ffc015;
border-radius: 25px;
padding: 7px 20px;
width:max-content;
border:none;
margin-bottom: 0.5rem;
font-weight: 700;
text-transform: uppercase;
font-size:16px;
`

function PleaseCallMeForm(props) {
    const  enqueueSnackbar  = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [tandc, setTandC] = useState(false)

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    
    const initialValue = {
        name:'',
        email:'',
        number:'',
        cashOrFinance: '',
        registeredBusiness: '',
        auditedFinancialStatements: ''
    }

    const cashOrFinanceOptions = ["Cash", "Finance"];
    const registeredBusinessOptions = ["Yes", "No"];
    const auditedFinancialStatementsOptions = ["Yes", "No"];

    function createLead(values) {

      var sourceId = 1;

      if (document.referrer) {
          if (document.referrer.indexOf("facebook") > -1) {
              sourceId = 2;
          }
      }


      let params = {}

      params.leadStatusId = 1
      params.dealerId =  DEALERID
      params.name = values.name;
      params.phoneNumber = values.phone;
      params.cashOrFinance = values.cashOrFinance;
      params.registeredBusinessMoreThan2Years = values.registeredBusiness;
      params.auditedFinancialStatements = values.auditedFinancialStatements;
      params.leadSourceId = sourceId
      params.TypeCode = "NEW"
      params.leadTypeId = 1

      let m = new Date()
      m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
      params.dateUpdated = new Date().toISOString();

      params.emailAddress = values.email;

      console.log("params", params)

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      axios({
          method: 'POST',
          url: `${BASE_URI}/leads`,
          data: params,
          cancelToken: source.token
      }).then((response) => {
          values.leadId = response.data.id;
          console.log("response", response);

      }).catch(error => {
          if (axios.isCancel(error)) return
          enqueueSnackbar("Unable to complete the request", { variant: 'error' });

      })
  }

  const validateForm = (values) => {
    let errors = {};
    if (values.cashOrFinance === "") {
      errors.cashOrFinance = "You must select a value.";
    }

    if (values.registeredBusiness === "") {
      errors.registeredBusiness = "You must select a value.";
    }

    if (values.auditedFinancialStatements === "") {
      errors.auditedFinancialStatements = "You must select a value.";
    }

    return errors;
  };

  
    return (
      <Root>
        <Formik
          initialValues={initialValue}
          validate={validateForm}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            if (tandc == true) {
              handleClickOpen();
              //createLead(values)
              setSubmitting(false);
              resetForm();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <HeadingHolder>
                <Heading>{props.heading}</Heading>
              </HeadingHolder>

              <InnerForm>
                <label htmlFor="name">Name</label>
                <Input
                  type="text"
                  name="name"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autocomplete={false}
                  value={values.name}
                  label="Name"
                />
                {errors.name && touched.name && errors.name}
              </InnerForm>

              <InnerForm>
                <label htmlFor="email">Email</label>
                <Input
                  type="email"
                  name="email"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />

                {errors.email && touched.email && errors.email}
              </InnerForm>

              <InnerForm>
                <label htmlFor="number">Phone number</label>
                <Input
                  type="number"
                  name="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.number}
                />

                {errors.number && touched.number && errors.number}
              </InnerForm>

              <InnerFormRadio>
                <Field
                  name="cashOrFinance"
                  label="Will you be purchasing the Truck Cash or will you need Finance?"
                  options={cashOrFinanceOptions}
                  component={FormikRadioGroup}
                />

                <Field
                  name="registeredBusiness"
                  label="If you require finance, do you have a registered business for more than 2 years"
                  options={registeredBusinessOptions}
                  component={FormikRadioGroup}
                />

                <Field
                  name="auditedFinancialStatements"
                  label="Do you have audited financial statements for the business?"
                  options={auditedFinancialStatementsOptions}
                  component={FormikRadioGroup}
                />
              </InnerFormRadio>

              <InnerFormCheck>
                <span>
                  By submitting this form I agree to the{" "}
                  <Link to="/terms">terms and conditions</Link> and{" "}
                  <Link to="/privacy">privacy policies</Link>{" "}
                  <input
                    type="checkbox"
                    required
                    onChange={() => {
                      setTandC(tandc ? false : true);
                    }}
                  />
                </span>
              </InnerFormCheck>

              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Thank You</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <span>
                  Thank you for your enquiry, we will be in touch
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Root>
    );
}

export default PleaseCallMeForm
