import React,{useState} from "react";

import "pure-react-carousel/dist/react-carousel.es.css";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    position: "relative",
    margin: "0.5rem",
  },
  carouselBack: {
    left: "10px",
    background: "#858585",
    borderRadius: "5px",
    height: "40px",
    width: "40px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%);",
    all: "unset",
    cursor: "pointer",
    "@media (max-width: 425px)": {
      top: "60%",
    },
  },
  carouselNext: {
    right: "10px",
    background: "#858585",
    borderRadius: "5px",
    height: "40px",
    width: "40px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%);",
    all: "unset",
    cursor: "pointer",

    "@media (max-width: 425px)": {
      top: "60%",
    },
  },
  carouselDots: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
    marginBottom: "15px",
    "& > button": {
      width: "15px",
      height: "15px",
      borderRadius: "50%",
      border: "none",
      padding: "0",
      cursor: "pointer",
      backgroundColor: "#EFDF00",
      marginRight: "10px",
    },
  },
  backgroundImage: {
    backgroundPosition: "center center",
    backgroundSize: "100% 100%!important",
    "@media (max-width: 624px)": {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top",
      height: "36%",
    },
  },

  slideMargin: {
    width: "calc(100% - 20px)",
    height: "calc(100%)",
    left: "10px",
    top: "10px",
  },
  slideImgHolder:{
    display: "flex",
overflow:'hidden',
  },
  slideImgHolderInner:{
    display: "flex",
    transform:(slidePosition) => `translateX(${slidePosition}%)`,
    "@media (max-width: 425px)": {
      transform:(slidePosition) => `translateX(${(slidePosition * 5) / 4  }%)`
    },
  },
  slideImg:{
    width: "calc(100% / 5 - 10px)",
    margin:' 0px 5px',
    "@media (max-width: 425px)": {
      width: "calc(100% / 4 - 10px)",
      minWidth:"calc(100% / 4 - 10px)"
    },
  },
}));

const CarouselDetailed = ({ slides, setSelectedImage }) => {

  const [index, setIndex] = useState(0)
  const [slidePosition, setSlidePosition] = useState(0)
  const classes = useStyles(slidePosition);

  const handelBack = () =>{
    if(slidePosition < 0){
      setSlidePosition(slidePosition + (100 / 5))
    }
  }

  const handelNext = () =>{
   
    setSlidePosition(slidePosition - (100 / 5))

    // console.log("length",slides.length)
    // console.log("index",index)
   
 // }
  }

  return (
    <div className={classes.sliderContainer}>
   <div className={classes.slideImgHolder}>
   <div className={classes.slideImgHolderInner}>
   
      {slides.map((img)=>{
        return(
          <div className={classes.slideImg}>
          <img src={img} width="100%" onClick={()=>{setSelectedImage(img)}}/>
        </div>
        )
      })}
      </div>
</div>
          {slides.length > 1 &&
     <>
      <div className={classes.carouselBack}  onClick={()=>{handelBack()}}>
        <ChevronLeftIcon style={{ fontSize: 40, color: "#EFDF00" }} />
      </div>
      <div className={classes.carouselNext} onClick={()=>{handelNext()}}>
        <ChevronRightIcon style={{ fontSize: 40, color: "#EFDF00" }} />
      </div>
      </>
      }
    </div>
  );
};

export default CarouselDetailed;
