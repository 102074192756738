import React from 'react'
import styled from 'styled-components'
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import PriceFormatter from './PriceFormatter';

const Card = styled.div`
width: calc(100%/1.05);

border: 1px solid #c0c0c0;
margin-left: 0.5rem;
border-radius:5px;
margin-right: 0.5rem;
margin-bottom: 1rem;

text-align:center;
color: #777777;
display:flex;
align-items:center;
flex-direction:column;
@media (min-width: 0px) and (max-width: 537px) {
    max-width: calc(100%/1);

  }

  @media (min-width: 538px) and (max-width: 768px) {
    max-width: calc(100%/1.1);
    width: 100%;
  }

`

const Make = styled.span`
font-size: 1.5em;
line-height: normal;
margin:5px 0px;
display:inline-block;
`

const Model = styled.div`
font-size: .9em;
margin:5px 0px;
display:inline-block;
`

const Year = styled.span`
font-size: 1em;
`
const Image = styled.img`
 width: 100%;
 height: auto;
 margin-bottom: 10px;
 @media (min-width: 0px) and (max-width: 637px) {
  height: auto;
}
@media (min-width: 538px) and (max-width: 768px) {
  height: auto;
}
`

const Price = styled.div`
font-size: 1.5em;
line-height: normal;
margin:5px 0px;
display:inline-block;
font-weight: 700;
`
const Vat = styled.span`
font-size: .7em;
`

const Cta = styled.div`
margin-bottom: 1rem;
margin-top: 1rem;
color: #ffffff;
background-color: #ffc015;
border-radius: 25px;
padding: 7px 20px;
width: 50%;
`
const CtaText = styled(Link)`
color: #ffffff;
background-color: #ffc015;
text-decoration: none;
font-weight: 700;

`
function TrailerCard(props) {
  const history = useHistory()
    return (
        <Card onClick={()=>{history.push(`/trailer/${props.id}` , {trailer: props})}} >
          <Image src={props.image}></Image>
          <Make>{props.make}</Make>
          <Model>{!!props?.year && (<Year>{props.year}</Year>)} {props.model}</Model>
          <Price>R {PriceFormatter(props.price  / 1.15)} <Vat >Excl Vat</Vat></Price>
          {!!props?.price && (<Price>R{PriceFormatter(props.price)} <Vat >Incl Vat</Vat></Price>)}
          <Cta>
            <CtaText to={"/stock/" + props.stockId}>Enquire</CtaText>
            </Cta>
      </Card>
    );
}

export default TrailerCard
