import React, { useState,useEffect } from 'react'   
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import { useSnackbar } from "notistack";


const Root = styled.div`

padding-left: 0.5rem;
padding-right: 0.5rem;
`

const Formheading = styled.p`
font-weight: 700;
text-align:center;

`
const Label = styled.p`
padding-left: 40px;


margin: 0px;

`
const FormInner = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;
width: 85%;
line-height:40px;

`
const InnerFormCheck = styled.div`
display:flex;
flex-direction: row;

width: 100%;
line-height:40px;
align-items: center;
`
const InnerFormCheckTC = styled.div`
display:flex;
flex-direction: row;
padding: 10px 10px;
width: 85%;
line-height:40px;
align-items: center;
@media (min-width: 0px) and (max-width: 768px) {
    width: 100%;
     }
`


const Button = styled.button`
color: white;
background-color: #ffc015;
border-radius: 25px;
padding: 7px 20px;
width:max-content;
border:none;
margin-top: 0.5rem;
margin-bottom: 0.5rem;
font-weight: 700;

font-size:16px;
`

const Text = styled.p`
margin:0px;
margin-bottom: 1rem;
line-height: 1.5;
`
const Textheading = styled.span`
margin:0px;
margin-bottom: 0rem;
padding:0px;
font-weight: 700;
`

const Input = styled.input`

outline:none;
border:none;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }

  
`
const InnerForm = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;
width: 85%;
`
function PQForm(props) {
    const [tandc,setTandC] = useState(false)
    const  {enqueueSnackbar}  = useSnackbar();

    const Invalues = {
        minor:'',
        mentallyunfit:'',
        IdentityNumber:'',
        adminOrder:'',
        currentdebt:'',
        debtagreement:'',
        pendingcredit:'',
        debt:'',
        creditCheck:'',
        tc:'',
    }

const isValid = false

  return(
    <Root>
        <Formheading>
        Please confirm the following
        </Formheading>
        <hr/>
        <Formik
                initialValues={Invalues}
                onSubmit={(values,{setSubmitting , resetForm}) => {
                
                  if(tandc == true){
                
                    //  alert(JSON.stringify(values) + JSON.stringify(props.vehicle))
                      enqueueSnackbar("Thank you for your submission, we will be in touch",{ variant: 'success' });
                      resetForm()
                      setSubmitting(false);
                   
                  
                  }
                  
                  }}
     >          
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         setFieldValue,
         isSubmitting,
 
       }) => (
        <Form  onSubmit={handleSubmit}>
    
         

          <FormInner role="group" aria-labelledby="checkbox-group">
       
       

          <InnerFormCheck>
             
           <Input
             type="checkbox"
             name="minor"
             setFieldValue ={setFieldValue}
             onChange={handleChange}
             onBlur={handleBlur}
             autocomplete={false}
             value={values.minor}
             label="Name"
           />
          <Label htmlFor="minor">  You are not a minor</Label>
           {errors.minor && touched.minor && errors.minor}
           </InnerFormCheck>
  
  
           <InnerFormCheck>
             
             <Input
               type="checkbox"
               name="mentallyunfit"
         
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.mentallyunfit}
               label="Name"
             />
            <Label htmlFor="mentallyunfit">You have never been declared mentally unfit by a court</Label>
             {errors.mentallyunfit && touched.mentallyunfit && errors.mentallyunfit}
             </InnerFormCheck>

             
          <InnerFormCheck>
             
             <Input
               type="checkbox"
               name="adminOrder"
               
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.adminOrder}
               label="Name"
             />
            <Label htmlFor="adminOrder">You are not subject to an administration order</Label>
             {errors.adminOrder && touched.adminOrder && errors.adminOrder}
             </InnerFormCheck>

             
          <InnerFormCheck>
             
             <Input
               type="checkbox"
               name="currentdebt"
               
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.currentdebt}
               label="Name"
             />
            <Label htmlFor="currentdebt"> You do not have any current debt re-arrangement in existence</Label>
             {errors.currentdebt && touched.currentdebt && errors.currentdebt}
             </InnerFormCheck>

             
          <InnerFormCheck>
             
             <Input
               type="checkbox"
               name="debtagreement"
               
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.debtagreement}
               label="Name"
             />
            <Label htmlFor="debtagreement">	You have not previously applied for a debt re-arrangement</Label>
             {errors.debtagreement && touched.debtagreement && errors.debtagreement}
             </InnerFormCheck>

             
          <InnerFormCheck>
             
             <Input
               type="checkbox"
               name="pendingcredit"
               
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.pendingcredit}
               label="Name"
             />
            <Label htmlFor="pendingcredit"> You do not have applications pending for credit, nor do you have any open quotations under section 92 of the National Credit Act 34 of 2005("National Credit Act")</Label>
             {errors.pendingcredit && touched.pendingcredit && errors.pendingcredit}
             </InnerFormCheck>

             
          <InnerFormCheck>
             
             <Input
               type="checkbox"
               name="debt"
               
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.debt}
               label="Name"
             />
            <Label htmlFor="debt">	You are not currently in debt counselling</Label>
             {errors.debt && touched.debt && errors.debt}
             </InnerFormCheck>

             
          <InnerFormCheck>
             
             <Input
               type="checkbox"
               name="creditCheck"
               
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.creditCheck}
               label="Name"
             />
            <Label htmlFor="creditCheck">You hereby consent to a credit check</Label>
             {errors.creditCheck && touched.creditCheck && errors.creditCheck}
             </InnerFormCheck>
             <InnerFormCheck>
             
             <Input
               type="checkbox"
               name="tc"
               
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.tc}
               label="Name"
             />
            <Label htmlFor="tc">I consent to the Vehicle Finance Terms & Conditions</Label>
             {errors.tc && touched.tc && errors.tc}
             </InnerFormCheck>
        
          </FormInner>

          <InnerFormCheckTC>
           <span>By submitting this form I agree to the <Link to="/terms">terms and conditions</Link> and <Link to="/privacy">privacy policies</Link> <input type="checkbox"   required onChange={()=>{setTandC(tandc? false:true)}}/></span>
           </InnerFormCheckTC>

           <InnerForm>
              <label htmlFor="name">Your Identity Number</label>
           <Input
             type="ceh"
             name="IdentityNumber"
             required
             onChange={handleChange}
             onBlur={handleBlur}
             autocomplete={false}
             value={values.IdentityNumber}
             label="Name"
           />
           {errors.IdentityNumber && touched.IdentityNumber && errors.IdentityNumber}
           </InnerForm>

          <Button type="submit">Agree & Continue</Button>
        </Form>
      )}
    </Formik>

    <Text>
    ELT Group is an Authorised Financial Services Provider. If you are not sure what to do you can contact us at any time in the chat below or via email at info@eltgroup.co.za or +27 11 683 3364 during our office hours
    </Text>

    <Text>
    <Textheading>Business Hours</Textheading><br/>
    Monday - Friday: 08:00 - 17:00<br/>
    Saturday: By appointment only<br/>
    Sunday & Public Holidays: Closed<br/>
    </Text>
    </Root>
  )
}

export default PQForm;


