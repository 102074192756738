import React from 'react'
import styled from 'styled-components'


const Root = styled.div`
margin: 0.5rem 0rem;
text-align:center;
letter-spacing:0.2px;
line-height:17.8px;
font-size:11.2px;
font-weight: bold;
color: #111111;
`
function Covidbanner() {
    return (
        <Root>
            Your safety is our concern, for more information on COVID-19 visit the <br/>
Official SA Government Website here
        </Root>
    )
}

export default Covidbanner
