import React,{useState,useContext,useEffect} from 'react'
import styled from 'styled-components'
import { DealerContext } from '../../context/Dealer';

const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 1rem;
background: #ffffff;
justify-content:center;
padding-bottom: 10rem;
border-radius:5px;
`

const Heading =styled.h1`
font-size: 1.8em;
color: #ffc015;
line-height: 100%;
margin: 30px auto;
text-align: center;
`

const Text =styled.p`
margin-top: 0;
margin-bottom: 1rem;
`
function OurHistory() {
    const {globalDealer} = useContext(DealerContext)

    console.log("globalDealer",globalDealer)
    return (
        <Root>
            <Heading>Our history</Heading>
            <Text>{globalDealer?.description}</Text>
        </Root>
    )
}

export default OurHistory
