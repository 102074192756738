import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components';
import ContactForm from '../../components/ContactForm';
import { DealerContext } from '../../context/Dealer';
import axios from 'axios';
import { BASE_URI } from '../../context/Constants';

const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 10px 0px;
padding-bottom: 0px;
background: #ffffff;
border-radius:5px;
`

const InnerHolder = styled.div`
max-width: 1404px;
display:flex;
padding: 15px 0px;
background: #ffffff;
@media (min-width: 0px) and (max-width: 425px) {
  flex-direction:column;
  }

`

const ContactDetailsHolder = styled.div`
max-width: 1404px;
background: #ffffff;
display:flex;

@media (min-width: 0px) and (max-width: 425px) {
    flex-direction:column;
    }
`

const ContactDetailsCard = styled.div`
width: calc(100%/3);
text-align:center;
:nth-child(2) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 425px) {
    width:unset;
    }
`


const ContactDetailsHeading = styled.div`
color: #000000;
background-color: #ffc015;
font-weight: 700;
padding:5px 0px;
`

const ContactDetailsText = styled.div`
padding: 10px 0px;
`

const InnerHolderMap = styled.div`
text-align:center;
width:83%;
margin: 0px 10px;
paddingBottom:50px;
@media (min-width: 0px) and (max-width: 425px) {
    width:unset;
    }
`

const InnerHolderMapHeading = styled.h1`
font-weight: 700;
font-size: 1.8em;
line-height: 100%;
margin: 30px auto;
text-align:center;

`

function ContactUs() {

    const { globalDealer } = useContext(DealerContext)
    const [dealerInfo, setDealerInfo] = useState()

    useEffect(() => {

        axios.get(`${BASE_URI}/Dealers/DealerContactDetails/${globalDealer.id}`, {

        }).then((result) => {


            setDealerInfo(result.data)
        });

    }, [])

    return (
        <Root>
            <InnerHolder>
                <ContactForm heading="CONTACT US" notes />
                <InnerHolderMap>
                    <InnerHolderMapHeading>
                        CONTACT BA Used Truck and Trailer
                    </InnerHolderMapHeading>
                    <iframe src={`https://maps.google.com/maps?q=${dealerInfo?.addressLongitude},${dealerInfo?.addressLatitude}&via=0&z=16&output=embed`} height="350px" width="100%" frameBorder="none"></iframe>
                </InnerHolderMap>
            </InnerHolder>
            <ContactDetailsHolder>



                <ContactDetailsCard>
                    <ContactDetailsHeading>
                        Physical Address
                    </ContactDetailsHeading>
                    <ContactDetailsText>{dealerInfo?.address?.streetAddress}</ContactDetailsText>
                </ContactDetailsCard>

                <ContactDetailsCard>
                    <ContactDetailsHeading>
                        Business Hours
                    </ContactDetailsHeading>
                    <ContactDetailsText>Monday-Friday 07:30-17:00</ContactDetailsText>
                </ContactDetailsCard>

                <ContactDetailsCard>
                    <ContactDetailsHeading>
                        General Enquiries
                    </ContactDetailsHeading>
                    <ContactDetailsText>Customer Care: {dealerInfo?.newCarsPhoneNumber}</ContactDetailsText>
                </ContactDetailsCard>

            </ContactDetailsHolder>
        </Root>
    )
}

export default ContactUs
