import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { BASE_URI, DEALERID } from "../context/Constants"
import axios from "axios";
import { useSnackbar } from "notistack";

const Root = styled.div`
display:flex;
`


const FormInner = styled(Form)`
display:flex;
flex-direction: column;
`
const InnerForm = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;

`

const InnerFormCheck = styled.div`
display:flex;
flex-direction: column;
padding:10px 10px;
width: 100%;
line-height:40px;
`

const Textarea = styled.textarea`

outline:none;
border:none;
min-height:80px;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }
`

const Input = styled.input`

outline:none;
border:none;
border-bottom:1px solid #ffc015;
:focus {
    border-bottom:1px solid blue;
  }

  
`
const HeadingHolder = styled.div`
background-color: #ffc015;
width: 100%;

`

const Heading = styled.h2`
color:white;
text-align:center;
margin: 10px 0px;
`

const Button = styled.button`
color: #ffffff;
background-color: #ffc015;
border-radius: 25px;
padding: 7px 20px;
width:max-content;
border:none;
margin-bottom: 0.5rem;
font-weight: 700;
text-transform: uppercase;
font-size:14px;
`

function SellYourTruckStep2(props) {
  const  {enqueueSnackbar}  = useSnackbar();
  const [loader, setLoader] = useState(false)
  const [loading, setLoading] = useState(false);
      const initialValue = {
          name:'',
          email:'',
          number:'',
          randvalue:'',
      }
  
      const [tandc,setTandC] = useState(false)
  
      const createLead = async (values,vehicle) => {
        console.log(vehicle)
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
    
        let params = {
          leadStatusId: 1,
          leadTypeId: 11, //tradein
          dealerId: DEALERID,
          name: values.name,
          firstName: values.name,
          lastName: values.lastName,
          phoneNumber: values.phone,
          emailAddress: values.email,
          leadSourceId: 1,
          TypeCode: "TRADEIN",
          notes: "",
          dateUpdated: new Date().toISOString(),
        };
    
        axios({
          method: "POST",
          url: `${BASE_URI}/leads`,
          data: params,
          cancelToken: source.token,
        })
          .then((response) => {
            createTradeIn(response?.data?.id);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              return;
            }
            setLoading(false);
            enqueueSnackbar("Failed to save the personal details", { variant: "error" });
          });
      };
    
      const createTradeIn = (leadId) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
    
  
        let params = {
          leadId: leadId,
          makeId: props.vehicle?.make,
          modelId: props.vehicle?.modelId,
          variantId: props.vehicle?.variant,
          year: parseInt(props.vehicle?.registrationyear),
          odometer: parseInt(props.vehicle?.kilometers),
          color: props.vehicle?.color,
          vinNumber: props.vehicle?.vin,
          registration: props.vehicle?.registrationNumber,
          transmission: props.vehicle?.gears,
          fuelType: props.vehicle?.fuel,
          
          // hasSpareKeys: props.vehicle?.hasSpareKeys,
          // hasServiceBook: props.vehicle?.hasServiceBook,
          // hasMaintenancePlan: props.vehicle?.hasMaintenancePlan,
          // hasWarranty: props.vehicle?.hasWarranty,
       
        };
    
        axios({
          method: "POST",
          url: `${BASE_URI}/TradeInVehicle/FromShared`,
          data: params,
          cancelToken: source.token,
        })
          .then((response) => {
            processLead(leadId);
    
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              return;
            }
            setLoading(false);
            enqueueSnackbar("Failed to save the vehicle details", { variant: "error" });
          });
      };

      const processLead = (leadId) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
    
        axios({
          method: "POST",
          url: `${BASE_URI}/Leads/ProcessLead/${leadId}`,
          cancelToken: source.token,
        })
          .then((response) => {
            setLoading(false);
            enqueueSnackbar("Successfully saved and submitted the captured details", { variant: "success" });
            
         
            
           
    
          })
          .catch((error) => {
            
            if (axios.isCancel(error)) {
              return;
            }
            setLoading(false);
            enqueueSnackbar("Failed to save the vehicle details", { variant: "error" });
    
          });
      };
      return (
          <Root>
              <Formik
                  initialValues={initialValue}
                  onSubmit={(values, {setSubmitting , resetForm}) => {
                  
                    setTimeout(() => {
                      if(tandc == true){
                   
                   
                     
                        createLead(values,props.vehicle)
                        setSubmitting(false);
                        resetForm();
                    
                    }
                    }, 500);
                   
                    
                    }}
       >          
         {({
           values,
           errors,
           touched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting,
   
         }) => (
           
           <FormInner onSubmit={handleSubmit}>
                 
  
                 <InnerForm>
                <label htmlFor="name">Name</label>
             <Input
               type="text"
               name="name"
               required
               placeholder="Your name"
               onChange={handleChange}
               onBlur={handleBlur}
               autocomplete={false}
               value={values.name}
             />
             {errors.name && touched.name}
             </InnerForm>
  
             <InnerForm>
             <label htmlFor="email">Email</label>
             <Input
               type="email"
               name="email"
               placeholder="Email"
               required
               onChange={handleChange}
               onBlur={handleBlur}
               value={values.email}
             />
  
             {errors.email && touched.email}
             </InnerForm>
  
             <InnerForm>
             <label htmlFor="number">Phone number</label>
             <Input
               type="number"
               name="number"
               placeholder="Telephone"
               onChange={handleChange}
               onBlur={handleBlur}
               value={values.number}
             />
  
             {errors.number && touched.number}
             </InnerForm>
  
             <InnerForm>
             <label htmlFor="number">Rand value</label>
             <Input
               type="number"
               name="randvalue"
               placeholder="Expected price"
               onChange={handleChange}
               onBlur={handleBlur}
               value={values.randvalue}
             />
  
             {errors.randvalue && touched.randvalue}
             </InnerForm>

  {props.notes?
             <InnerForm>
             <label htmlFor="number">Notes</label>
             <Textarea
               type="text"
               name="notes"
               onChange={handleChange}
               onBlur={handleBlur}
               value={values.notes}
             />
  
             </InnerForm>: ""
   }
             <InnerFormCheck>
             <span>By submitting this form I agree to the <Link to="/terms">terms and conditions</Link> and <Link to="/privacy">privacy<br/> policies</Link> <input type="checkbox" required onChange={()=>{setTandC(tandc? false:true)}}/></span>
             </InnerFormCheck>
  
             <Button type="submit" disabled={isSubmitting}>
             That's it, Submit Your Car Details
             </Button>
             
           </FormInner>
         )}
       </Formik>
          </Root>
  )
}

export default SellYourTruckStep2;
