import React, { useContext } from 'react'
import styled from 'styled-components';
import VehicleCard from '../../components/VehicleCard';
import { VehicleContext } from '../../context/UsedVehicles';


const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 15px 0px;
background: #ffffff;
border-radius:5px;
`

const VehicleCardHolder = styled.div`
max-width: 1404px;
padding: 15px 0px;
background: #ffffff;

display:flex;
justify-content:center;
  @media (min-width: 0px) and (max-width: 425px) {
    flex-wrap:wrap;
  }
  @media (min-width: 426px) and (max-width: 768px) {
    flex-wrap:wrap;
  }
`

const Heading =styled.h1`
font-size: 1.8em;
color: #ffc015;
line-height: 100%;
margin: 30px auto;
text-align: center;
`

const Text =styled.p`
text-align: center;
font-size: 16px;
margin-left: 0.5rem;
margin-right: 0.5rem;
line-height: 25px;
`

function Homepage() {

  const {vehicles} = useContext(VehicleContext)

    return (
        <Root>
            <VehicleCardHolder>
          {vehicles.map((vehicle,index)=>{
            if(index < 4){
            return(
              <VehicleCard type="TruckHome" make={vehicle.brand} model={vehicle.model} mileage={vehicle.mileage + "km" + " | " + vehicle.year} price={vehicle.price} image={vehicle.image} stockId={vehicle.stockId} vehicle={vehicle}/>
            )
          }
          })}
            </VehicleCardHolder>

        <Heading>WELCOME TO BA USED TRUCK AND TRAILER</Heading>
        <Text>BA Used Truck & Trailer's core focus is on the sales of pre-owned commercial vehicles and trailers. Each vehicle and trailer have been carefully selected by our experienced team at BA Used Truck & Trailer, ensuring a comprehensive range of quality pre-owned vehicles and trailers at competitive prices.<br/><br/>
        Having a family owned and run multi-company ensures we create a 'one-stop-shop' solution for all our customer’s needs. prices.</Text>
        </Root>
    )
}

export default Homepage
