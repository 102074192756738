import React from "react";
import styled from "styled-components";

const Root = styled.div`
  max-width: 1404px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 15px 2rem;
  background: #ffffff;
  justify-content: center;
  padding-bottom: 10rem;
  border-radius: 5px;
`;

const Heading = styled.h1`
  font-size: 1.8em;
  color: #ffc015;
  line-height: 100%;
  margin: 30px auto;
`;

const SubHeading = styled.div`
  margin: 15px 0;
  font-weight: bold;
  font-size: 1.25rem;
  color: #777;
`;

const Link = styled.a`
  line-height: 2.5;
  cursor: pointer;
  color: #ffc015;
  text-decoration: none;
  &:hover {
      color: #000;
  }
`;

const ListItem = styled.li`
  line-height: 1.5;
`

const Text = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.5;
`;
function Terms() {
  return (
    <Root>
      <Heading>Terms & Conditions</Heading>

      <SubHeading>Ownership & Copyright</SubHeading>

      <Text>
        The copyright in all information, material and logos vesting in BA Used
        Truck and Trailer shall continue to vest in BA Used Truck and Trailer
        and no right, title or interest in any proprietary material or
        information contained in this website is granted to you hereunder.
      </Text>
      <Text>
        Without derogating from the foregoing, BA Used Truck and Trailer hereby
        authorises you to view, copy, download to a local drive, print and
        distribute the content of this website, or any part thereof, provided
        that:
      </Text>

      <ul>
        <ListItem>Such content is used for information purposes only;</ListItem>
        <ListItem>Such content is used for non-commercial purposes.</ListItem>
        <ListItem>
          You do not incorporate any material from this website in any other
          work, publication or website either of your own or belonging to any
          third party;
        </ListItem>
        <ListItem>
          Any reproduction of the content of this website, or portion thereof,
          must include these disclaimers and copyright notices in their
          entirety.
        </ListItem>
      </ul>

      <Text>
        Documents specified above do not include the design or layout of this
        website. Elements of this website are protected by law and may not be
        copied or imitated in whole or in part. No logo, graphic, sound or image
        from this website may be copied or retransmitted unless expressly
        permitted by BA Used Truck and Trailer.
      </Text>

      <SubHeading>Information Purposes Only</SubHeading>

      <Text>
        This website is only intended to provide general information regarding
        BA Used Truck and Trailer and its products and services. It is not
        intended to provide exhaustive treatment of any subject dealt with and
        BA Used Truck and Trailer expressly reserves the right, in its sole and
        absolute discretion, to alter or amend any criteria or information set
        out on this website without notice. Accordingly, the information in this
        website is not intended to constitute financial, accounting, tax, legal,
        investment, consulting or other professional advice or services. Any
        quotation provided or product offered on or through this website
        (including without limitation any motor vehicle and related products,
        finance programme, financial service, insurance and the like) is shown
        for indicative purposes only and does not constitute an offer capable of
        binding acceptance on the terms displayed. Before making any decision or
        taking any action, which might affect your personal finances or
        business, you should consult a qualified professional advisor. This
        website could include technical or other inaccuracies or typographical
        errors. Changes are periodically added to the information herein and
        these changes will be incorporated in new editions of this website. BA
        Used Truck and Trailer may make improvements and/or changes to this
        website at any time. This website may now, or hereafter from time to
        time, contain certain statements or information with respect to (i) the
        projection of revenues, income, earnings per share, capital
        expenditures, dividends, capital structure, or other financial items;
        (ii) the plans, objectives and/or projections of BA Used Truck and
        Trailer for future operations, including those relating to the services
        of BA Used Truck and Trailer; (iii) future economic performance. BA Used
        Truck and Trailer wishes to caution you that such projections are only
        predictions and that actual events or results may differ materially.
      </Text>

      <SubHeading>Transmission of Information</SubHeading>

      <Text>
        Your attention is drawn to the fact that information transmitted via the
        Internet, including without limitation email, is susceptible to
        monitoring and interception. You are therefore discouraged from
        transmitting to BA Used Truck and Trailer any information which may be
        confidential, proprietary or sensitive. You shall bear all risk of
        transmitting such information in this manner and under no circumstances
        will BA Used Truck and Trailer be liable for any loss, harm or damage
        suffered by you as a result thereof. BA Used Truck and Trailer reserves
        the right to request independent verification of any information
        transmitted via email and you consent to such verification should BA
        Used Truck and Trailer deem it necessary. BA Used Truck and Trailer
        requests that no unsolicited confidential or proprietary information be
        sent to BA Used Truck and Trailer. Any information or material sent to
        BA Used Truck and Trailer will be deemed not to be confidential, unless
        otherwise agreed in writing by BA Used Truck and Trailer.
      </Text>

      <SubHeading>Links to 3rd Party Websites</SubHeading>

      <Text>
        Linked sites are not subject to the control of BA Used Truck and Trailer
        and BA Used Truck and Trailer is not responsible for the contents of any
        linked site or any link contained in a linked site. BA Used Truck and
        Trailer provides links to you only as a convenience and the inclusion of
        any link does not imply endorsement by BA Used Truck and Trailer of such
        a site.
        <br />
        <br />
        This site may link to third party websites that may contain information
        or material that could be found inappropriate or offensive. As these
        websites are not under the control of BA Used Truck and Trailer, you
        acknowledge that we are not responsible for the accuracy, copyright
        compliance, legitimacy, decency or any other content aspect on third
        party websites. Including such link or links does not imply endorsement
        or association of any third party website that may be referred to on the
        website.
      </Text>

      <SubHeading>Disclaimer</SubHeading>

      <Text>
        All type of material, information, software, products, offers and
        services provided through the website (the "content") is provided as it
        stands and as it is available for your usage. The content is provided
        without any warranties, whether direct or implied, including, but not
        limited to, inferred warranties of merchantability, suitability for
        specific purposes or non-infringement. BA Used Truck and Trailer and its
        agents do not certify that the content is precise, reliable or exact;
        that the website will be available at any particular time or location;
        that any flaws or errors will be amended; or that the content is free of
        viruses or similar components. Using this site falls solely at your own
        risk. As certain jurisdictions do not permit the exclusion of certain
        warranties, these exclusions may not apply to all users.
        <br /> <br />
        This website and the information contained therein is therefore provided
        "as is" and BA Used Truck and Trailer makes no express or implied
        representations or warranties with regard thereto. Without limiting the
        generality of the afore going:-
      </Text>

      <ul>
        <ListItem>
          BA Used Truck and Trailer does not warrant that the website or
          information will be error-free or will meet any particular criteria of
          performance or quality. BA Used Truck and Trailer expressly disclaims
          all implied warranties, including, without limitation, warranties of
          merchantability, title, fitness for a particular purpose, year
          2000-compliance, non-infringement, compatibility, security and
          accuracy; and
        </ListItem>
        <ListItem>
          whilst BA Used Truck and Trailer has taken reasonable measures to
          ensure the integrity of this website and its contents, no warranty,
          whether express or implied is given that any files, downloads or
          applications available via this website are free of viruses, trojans,
          bombs, time-locks or any other data or code which has the ability to
          corrupt or affect the operation of your system.
        </ListItem>
      </ul>

      <SubHeading>Risk & Limitation of Liability</SubHeading>

      <Text>
        Under no circumstances shall BA Used Truck and Trailer or its agents be
        liable for any direct, indirect, punitive, incidental, special, or
        consequential damages that result from the use of, or inability to use,
        this site. This limitation applies whether the alleged liability is
        based on contract, tort, negligence, strict liability, or any other
        basis, even if we have been advised of the possibility of such damage.
        Because some jurisdictions do not allow the exclusion or limitation of
        incidental or consequential damages, our liability in such jurisdictions
        shall be limited to the extent permitted by law.
        <br />
        <br />
        Your use of this website and the information contained therein is
        entirely at your own risk and you assume full responsibility and risk of
        loss resulting from the use thereof. Neither BA Used Truck and Trailer,
        its affiliates, or their shareholders, agents, consultants or employees
        will be liable for any damages whatsoever, including, without limitation
        any direct, indirect, special, incidental, consequential or punitive
        damages, whether in an action arising out of contract, statute, delict
        or otherwise, relating to the use of this website or the information
        contained therein.
        <br />
        <br />
        If any of the foregoing is not fully enforceable for any reason, the
        remainder shall nonetheless continue to apply.
        <br />
        <br />
        Any dispute or purported dispute arising out of this web site shall be
        governed by and construed in accordance with the law of the Republic of
        South Africa and all disputes, actions and other matters relating
        thereto will be determined in accordance with such law. The parties to
        any dispute arising out of this web site hereby consent and submit to
        the jurisdiction of the Court of the Republic of South Africa sitting at
        Johannesburg.
      </Text>

      <SubHeading>Intellectual Property</SubHeading>

      <Text>
        You accept and agree that all content and materials accessible on the
        site are fully protected by copyrights, trademarks, service marks,
        patents, trade secrets or any additional proprietary rights and laws
        that may apply. Unless granted permission by BA Used Truck and Trailer,
        you agree not to sell, license, rent, alter, distribute, copy,
        reproduce, communicate, publicly display, publicly perform, publish,
        adapt, edit or create derived works from any material or content
        featured on the website.
        <br />
        <br />
        Reproduction, copying or redistribution of any design elements or
        material is strictly prohibited for commercial purposes, unless granted
        express written permission by BA Used Truck and Trailer. For information
        on having any special requests to this nature granted, please contact
        us.
      </Text>

      <SubHeading>Contact Information</SubHeading>
      <Text>To get in contact with our team, please contact us.</Text>

      <SubHeading>Indemnification</SubHeading>

      <Text>
        Upon a request, you agree to defend and assure BA Used Truck and Trailer
        and its affiliated companies, as well as its employees, contractors,
        officers and directors from all liabilities, claims, and expenses
        (including legal fees) that arise from the misuse of this website.
      </Text>

      <SubHeading>Severability and Integration</SubHeading>

      <Text>
        Unless stated otherwise, this agreement comprises the entire agreement
        between you and BA Used Truck and Trailer in regards to the website, and
        takes precedence over all prior or concurrent communication between you
        and BA Used Truck and Trailer in relation to the website. If any part of
        these Terms of Use is determined unsound or unenforceable, that part
        shall be interpreted in a manner consistent with appropriate law, to
        reflect the original intentions of all parties as closely as possible,
        and the remaining parts will continue in full force and conclusion.
      </Text>

      <SubHeading>Jurisdiction</SubHeading>

      <Text>
        These Terms of Use are governed by and interpreted in accordance with
        South African law. You hereby consent to obligatory arbitration in South
        Africa to resolve any disputes that may arise under these Terms of Use.
      </Text>

      <SubHeading>Additional Links</SubHeading>

      <ul>
        <ListItem>
          <Link href="/policies">Our Policies</Link>
        </ListItem>
        <ListItem>
          <Link href="/privacy">Privacy Policy</Link>
        </ListItem>
        <ListItem>
          <Link href="/cookies">Cookie Policy</Link>
        </ListItem>
      </ul>
    </Root>
  );
}

export default Terms;
