import React, { useEffect,useState } from 'react'
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import PleaseCallMeForm from '../../components/PleaseCallMeForm';
import { DEALERID,BASE_URI } from '../../context/Constants';
import Carousel from '../../components/Carousel'
import axios  from 'axios'

const Root = styled.div`
max-width: 1404px;
margin-left: 0.5rem;
margin-right: 0.5rem;
padding: 10px 0px;
padding-bottom: 0px;
background: #ffffff;
border-radius:5px;
`

const Make = styled.div`
    font-weight: 700;
    font-size: 2em;
    margin-bottom: 10px;
    @media (min-width: 1200px) and (max-width: 1024px) {
        font-size: 1.6em;
        }
`

const Model = styled.div`
    font-size: 12px;
    margin-bottom: 15px;
`

const InnerHolder = styled.div`
max-width: 1404px;
display:flex;
padding: 20px;
background: #ffffff;
@media (min-width: 0px) and (max-width: 825px) {
  flex-direction:column;
  }

`

const Image = styled.img`
 width: 100%;
 hight: 100%;
`

const ContactDetailsHolder = styled.span`
max-width: 1404px;
background: #ffffff;
display: inline-block;
margin-left: 10px;
margin-top: 67px;
@media (min-width: 0px) and (max-width: 825px) {
    flex-direction:column;
    }
`

const InnerHolderDetail = styled.div`
text-align:center;
width:83%;
margin: 0px 10px;
paddingBottom:50px;
@media (min-width: 0px) and (max-width: 825px) {
    width:unset;
    }
`

const Price = styled.span`
display: block;
margin: 20px 0;
font-size: 20px;
font-weight: bold;
`

const Vat = styled.span`
font-size: .7em;
`

function TrailerDetail(props) {
  const { state } = props.location;
  const { id } = useParams();
  const [activeVehicleGallery,setActiveVehicleGallery] = useState([])
  const [selectedImage, setSelectedImage] = useState();
  let trailer = state?.trailer;

  useEffect(() => {

      //get trailer from list using id


      axios({
        method: 'GET',
        url: `${BASE_URI}/stock/${parseInt(id)}`,
    }).then((response) => {
      setActiveVehicleGallery(response.data?.mainUrls)
      setSelectedImage(response.data?.mainUrls[0]);
    }).catch(error => {
      
    })


    
  }, [])

  return (
    <Root>
      <InnerHolder>
        {trailer && (<InnerHolderDetail>
          <Make>{`${trailer.make}`}</Make>
          <Model>{`${trailer.year} ${trailer.make} ${trailer.model} ${trailer.price ? `R${trailer.price}` : ''}`}</Model>
       
          <img src={selectedImage} width="100%"/>
   
   {activeVehicleGallery.length > 1 &&
                                       <div style={{ marginBottom: '1rem' }}>
                                           {

                                               <Carousel naturalSlideWidth={100}
                                                   naturalSlideHeight={60}
                                                   totalSlides={activeVehicleGallery.length}
                                                   visibleSlides={4}
                                                   isPlaying={true}
                                                   infinite
                                                  
                                                   slides={activeVehicleGallery}
                                                   onClick={(slide) => {
                                                     console.log("slide",slide.target.currentSrc)
                                                      if(slide.target.currentSrc){
                                                       setSelectedImage(slide.target.currentSrc);
                                                      }
                                                   }}
                                               />
                                           }
                                       </div>
                                   }
        </InnerHolderDetail>)}
        <ContactDetailsHolder>
        <PleaseCallMeForm heading="PLEASE CALL ME" />
        {trailer?.price && (<Price>R{trailer.price} <Vat >Excl Vat</Vat></Price>)}
        <span>Terms and Conditions apply*</span>
        
        </ContactDetailsHolder>
        {/* {trailer.images && trailer.images.map(image =>(
          <Image src={image.imageUrl}></Image>
        ))} */}
      </InnerHolder>
    </Root>
  );
}

export default TrailerDetail;
